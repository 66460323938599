import { useXeroAccountsApi } from 'apiProviders'
import { prop } from 'ramda'
import { Autocomplete, AutocompleteProps } from './autocomplete'

export default function XeroAccountAutocomplete(
    props: Omit<AutocompleteProps<string, false, true, false>, 'options' | 'getOptionLabel'>,
) {
    const accountsApi = useXeroAccountsApi()

    return (
        <Autocomplete
            {...props}
            options={accountsApi.data?.map(prop('AccountID')) ?? []}
            getOptionLabel={id => {
                const account = accountsApi.data?.find(x => x.AccountID === id)
                return account ? `${account.Code} - ${account.Name}` : ''
            }}
        />
    )
}
