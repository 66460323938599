import {
    ConjunctionalAgencySchema,
    InternalConjunctionalAgencySchema,
} from '@fastre/core/src/schemas/conjunionalAgency'
import { FrontendUserSchema } from '@fastre/core/src/schemas/userMembership'
import { zodResolver } from '@hookform/resolvers/zod'
import { DeleteRounded } from '@mui/icons-material'
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    Modal,
    ModalClose,
    ModalDialog,
    Stack,
    Switch,
    Typography,
    useTheme,
} from '@mui/joy'
import List from '@mui/joy/List'
import ListDivider from '@mui/joy/ListDivider'
import ListItem from '@mui/joy/ListItem'
import ListItemContent from '@mui/joy/ListItemContent'
import { useApi } from 'api'
import { useConjunctionalAgenciesApi, useFindUserFromId, useUsersApi } from 'apiProviders'
import { useUserData } from 'auth'
import Loading from 'components/Loading'
import { SlotAutocomplete } from 'components/autocomplete'
import { SlotInput, SlotWrapper } from 'components/input'
import { dontCloseOnBackgroundClick } from 'components/modal'
import SectionHead from 'components/sectionHead'
import { useShowSnack } from 'components/snackbar'
import { MobileFriendlyStack } from 'components/stack'
import { useMaybeState } from 'helperFunctions/react'
import { prop, sortBy, without } from 'ramda'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useDebounce } from 'use-debounce'

interface XeroContact {
    id: string
    name: string
}

const XeroContactSearch = ({
    value,
    onChange,
}: {
    value: XeroContact | undefined
    onChange: (contact: XeroContact | null) => void
}) => {
    const api = useApi()

    const [search, setSearch] = useState('')
    const [debouncedSearch] = useDebounce(search, 200)
    const [contacts, setContacts] = useState<{ id: string; name: string }[]>([])

    console.log('debouncedSearch', debouncedSearch)

    useEffect(() => {
        api.post('/xero/contacts/search', { search: debouncedSearch }).then(({ data }) =>
            setContacts(data.map(x => ({ id: x.ContactID, name: x.Name }))),
        )
    }, [debouncedSearch])

    return (
        <Autocomplete
            value={value}
            onChange={(e, value) => onChange(value)}
            options={contacts}
            getOptionLabel={prop('name')}
            onInputChange={(e, value) => setSearch(value)}
        />
    )
}

const EditAgencyModal = ({
    agency,
    users,
    close,
}: {
    agency: {} | (ConjunctionalAgencySchema & { conjunctionalAgencyId: string })
    close
    users: FrontendUserSchema[]
}) => {
    const api = useApi()
    const conjunctionalAgenciesApi = useConjunctionalAgenciesApi()
    const usersApi = useUsersApi()
    const showSnack = useShowSnack()
    const findUserFromId = useFindUserFromId()
    const isNew = (agency as any).conjunctionalAgencyId == undefined
    const { user } = useUserData()

    const [loading, setLoading] = useState(false)

    const currentUsers = users
        .filter(
            user =>
                user.conjunctionalAgencyId ==
                (agency as InternalConjunctionalAgencySchema).conjunctionalAgencyId,
        )
        .map(prop('userId'))

    const { register, control, handleSubmit, formState, getValues, setValue } =
        useForm<ConjunctionalAgencySchema>({
            defaultValues: agency
                ? {
                      ...agency,
                      userIds: currentUsers,
                  }
                : {},
            resolver: zodResolver(ConjunctionalAgencySchema),
        })

    if (Object.keys(formState.errors).length > 0) {
        console.log(formState.errors)
    }

    const onSubmit: SubmitHandler<ConjunctionalAgencySchema> = async data => {
        setLoading(true)
        try {
            if (isNew) {
                await api.post('/conjunctionalagency/create', data)
                close()
            } else {
                await api.post(`/conjunctionalagency/${(agency as any).conjunctionalAgencyId}/update`, {
                    ...data,
                    removedUserIds: without(data.userIds, currentUsers),
                })
            }
            await Promise.all([conjunctionalAgenciesApi.refresh(), usersApi.refresh()])
            showSnack(`Conjunctional Agency ${isNew ? 'Added' : 'Saved'}`, 'success')
            close()
        } catch (e) {
            console.error(e)
            showSnack('Error saving conjunctional agency', 'danger')
        } finally {
            setLoading(false)
        }
    }

    return (
        <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
        >
            <ModalDialog>
                <ModalClose />
                <DialogTitle>
                    {isNew ? 'Create Conjunctional Agency' : 'Edit Conjunctional Agency'}
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Controller
                            name="licenseeName"
                            control={control}
                            render={field => (
                                <SlotInput
                                    {...field}
                                    label="Licensee Name"
                                />
                            )}
                        />
                        <MobileFriendlyStack>
                            <Controller
                                name="abn"
                                control={control}
                                render={field => (
                                    <SlotInput
                                        {...field}
                                        label="ABN"
                                    />
                                )}
                            />
                            <Controller
                                name="acn"
                                control={control}
                                render={field => (
                                    <SlotInput
                                        {...field}
                                        label="ACN"
                                    />
                                )}
                            />
                        </MobileFriendlyStack>
                        <MobileFriendlyStack>
                            <Controller
                                name="licenseNumber"
                                control={control}
                                render={field => (
                                    <SlotInput
                                        {...field}
                                        label="Licensee Number"
                                    />
                                )}
                            />
                            <Controller
                                name="licenseExpiry"
                                control={control}
                                render={field => (
                                    <SlotInput
                                        {...field}
                                        type="date"
                                        label="Licensee Expiry"
                                    />
                                )}
                            />
                        </MobileFriendlyStack>
                        <Controller
                            name="agencyCommission"
                            control={control}
                            render={field => (
                                <SlotInput
                                    {...field}
                                    type="number"
                                    endDecorator="%"
                                    label="Agent Commission"
                                    disabled={!user.permissions.includes('users.edit')}
                                />
                            )}
                        />
                        {user.permissions.includes('users.edit') && (
                            <Controller
                                name="agencyCommissionBeforeHeadOfficeCommission"
                                control={control}
                                disabled={!user.permissions.includes('users.edit')}
                                render={field => (
                                    <FormControl>
                                        <FormLabel>Franchise Fee Applied To Agent Commission</FormLabel>
                                        <Switch
                                            {...field.field}
                                            checked={field.field.value}
                                            onChange={e => field.field.onChange(e.target.checked)}
                                            startDecorator={({ checked }) => (
                                                <Typography sx={{ opacity: !checked ? 1 : 0.35 }}>
                                                    After Franchise Fee
                                                </Typography>
                                            )}
                                            endDecorator={({ checked }) => (
                                                <Typography sx={{ opacity: checked ? 1 : 0.35 }}>
                                                    Of 100%
                                                </Typography>
                                            )}
                                            sx={{
                                                mr: 'auto',
                                            }}
                                        />
                                    </FormControl>
                                )}
                            />
                        )}

                        <Controller
                            name="bankDetails.accountName"
                            control={control}
                            render={field => (
                                <SlotInput
                                    {...field}
                                    label="Bank Details"
                                    type="object"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                />
                            )}
                        />
                        <MobileFriendlyStack>
                            <Controller
                                name="bankDetails.bsb"
                                control={control}
                                render={field => (
                                    <SlotInput
                                        {...field}
                                        label="BSB"
                                    />
                                )}
                            />
                            <Controller
                                name="bankDetails.accountNumber"
                                control={control}
                                render={field => (
                                    <SlotInput
                                        {...field}
                                        label="Account Number"
                                    />
                                )}
                            />
                        </MobileFriendlyStack>

                        <Controller
                            name="userIds"
                            control={control}
                            render={field => (
                                <SlotAutocomplete
                                    {...field}
                                    label="Users"
                                    multiple
                                    options={usersApi.maybeData.orSome([]).map(prop('userId'))}
                                    getOptionLabel={userId =>
                                        findUserFromId(userId)
                                            .map(user => `${user.firstName} ${user.lastName}`)
                                            .orSome('loading...')
                                    }
                                    onChange={(e, value) => field.field.onChange(value)}
                                />
                            )}
                        />

                        <Controller
                            name="xero"
                            control={control}
                            render={field => (
                                <SlotWrapper
                                    {...field}
                                    label="Xero Contact"
                                >
                                    <XeroContactSearch {...field.field} />
                                </SlotWrapper>
                            )}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        loading={loading}
                    >
                        Save
                    </Button>
                    <Button
                        onClick={close}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    {!isNew && (
                        <Button
                            color="danger"
                            variant="outlined"
                            loading={loading}
                            sx={{ mr: 'auto' }}
                            startDecorator={<DeleteRounded />}
                            onClick={async () => {
                                setLoading(true)
                                await api.post(`/conjunctionalagency/delete`, {
                                    conjunctionalAgencyId: (agency as any).conjunctionalAgencyId,
                                })
                                await Promise.all([conjunctionalAgenciesApi.refresh(), usersApi.refresh()])
                                showSnack('Conjunctional Agency Deleted', 'success')
                                close()
                            }}
                        >
                            Delete
                        </Button>
                    )}
                </DialogActions>
            </ModalDialog>
        </form>
    )
}

const AgenciesList = () => {
    const theme = useTheme()
    const conjunctionalAgenciesApi = useConjunctionalAgenciesApi()
    const usersApi = useUsersApi()
    const { user } = useUserData()

    const [maybeEditAgency, setEditAgency] = useMaybeState<
        {} | (ConjunctionalAgencySchema & { conjunctionalAgencyId?: string })
    >()

    return (
        <>
            <SectionHead title="Conjunctional Agencies" />
            {conjunctionalAgenciesApi.maybeData
                .map(agencies => (
                    <Box
                        //sx={{ display: { xs: 'block', sm: 'none' } }}
                        sx={{ maxWidth: theme.breakpoints.values.sm + 'px' }}
                    >
                        {sortBy(a => a.licenseeName.toLowerCase(), agencies)
                            /*.filter(
                                    agency =>
                                        user.permissions.includes('users.edit') ||
                                        user.conjunctionalAgencyId == agency.conjunctionalAgencyId,
                                )*/
                            .map(agency => (
                                <List
                                    key={agency.conjunctionalAgencyId}
                                    size="sm"
                                    sx={{
                                        '--ListItem-paddingX': 0,
                                    }}
                                >
                                    <ListItem
                                        component="a"
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'start',
                                            cursor: 'pointer',
                                            textDecoration: 'none',
                                            ':hover': {
                                                backgroundColor: 'var(--joy-palette-background-level2)',
                                                borderRadius: 'sm',
                                            },
                                            px: 1,
                                            pt: 1,
                                        }}
                                        onClick={() => setEditAgency(agency)}
                                    >
                                        <ListItemContent
                                            sx={{
                                                display: 'flex',
                                                gap: 4,
                                                alignItems: 'start',
                                            }}
                                        >
                                            <Typography
                                                fontWeight={600}
                                                gutterBottom
                                            >
                                                {agency.licenseeName}
                                            </Typography>
                                            <Box sx={{ flexGrow: 1 }} />
                                            <Box>
                                                {usersApi.maybeData
                                                    .orSome([])
                                                    .filter(
                                                        user =>
                                                            user.conjunctionalAgencyId ==
                                                            agency.conjunctionalAgencyId,
                                                    )
                                                    .map(user => (
                                                        <Chip
                                                            key={user.userId}
                                                            variant="soft"
                                                            size="sm"
                                                            sx={{
                                                                mx: 1,
                                                            }}
                                                        >
                                                            {user.firstName} {user.lastName}
                                                        </Chip>
                                                    ))}
                                            </Box>
                                        </ListItemContent>
                                    </ListItem>
                                    <ListDivider />
                                </List>
                            ))}
                        {user.permissions.includes('users.edit') && (
                            <Button
                                sx={{ mt: 2 }}
                                onClick={() => setEditAgency({})}
                                variant="outlined"
                            >
                                New Agency
                            </Button>
                        )}
                    </Box>
                ))
                .orSome(<Loading />)}
            <Modal
                open={maybeEditAgency.isSome()}
                onClose={dontCloseOnBackgroundClick(() => setEditAgency(undefined))}
            >
                {usersApi.maybeData
                    .map(users =>
                        maybeEditAgency
                            .map(agency => (
                                <EditAgencyModal
                                    agency={agency}
                                    users={users}
                                    close={() => setEditAgency(undefined)}
                                />
                            ))
                            .orSome(<></>),
                    )
                    .orSome(<Loading />)}
            </Modal>
        </>
    )
}

const ConjunctionalAgencies = () => {
    return (
        <Box
            sx={{
                px: {
                    xs: 2,
                    sm: 4,
                },
            }}
        >
            <AgenciesList />
        </Box>
    )
}

export default ConjunctionalAgencies
