import { Box, Button, Stack, Typography, useColorScheme } from '@mui/joy'
import { useApi } from 'api'
import { useOrgIntegrationsApi } from 'apiProviders'
import { useUserData } from 'auth'
import Loading from 'components/Loading'
import XeroAccountAutocomplete from 'components/xeroAccountAutocomplete'
import { useOrgId } from 'helpers'
import xeroImg from 'images/xero/Logo - Blue.png'
import { useState } from 'react'

const Xero = () => {
    const api = useApi()
    const { user } = useUserData()
    const orgId = useOrgId()
    const integrationsApi = useOrgIntegrationsApi()

    const [loading, setLoading] = useState(false)
    const [conjAccountIdLoading, setConjAccountIdLoading] = useState(false)

    const xero = integrationsApi.data?.xero

    const { VITE_APP_STAGE } = import.meta.env

    const redirect_uri = import.meta.env.DEV
        ? 'https://ln6iauih0l.execute-api.ap-southeast-2.amazonaws.com/xero/authredirect'
        : `https://api.${VITE_APP_STAGE == 'prod' ? '' : VITE_APP_STAGE + '.'}fastre.com.au/xero/authredirect`

    return (
        <Box>
            <Stack gap={2}>
                <Box sx={{ height: '120px', width: '120px' }}>
                    <img
                        src={xeroImg}
                        style={{ height: '120px', width: '120px' }}
                    />
                </Box>
                <Box>
                    {xero ? (
                        <>
                            <Typography>Connected to {xero.tenants[0].tenantName}</Typography>
                            <Button
                                sx={{ mt: 2 }}
                                variant="outlined"
                                color="danger"
                                loading={loading}
                                onClick={async () => {
                                    setLoading(true)
                                    await api.delete('/xero')
                                    await integrationsApi.refresh()
                                    setLoading(false)
                                }}
                            >
                                Disconnect
                            </Button>
                            <Stack sx={{ mt: 2, gap: 2, maxWidth: '400px' }}>
                                <XeroAccountAutocomplete
                                    label="Conjunctional Agency Account"
                                    value={xero.config?.conjunctionalAgencyAccountId}
                                    loading={conjAccountIdLoading}
                                    onChange={async (e, value) => {
                                        setConjAccountIdLoading(true)
                                        await api.post('/xero/updateconfig', {
                                            conjunctionalAgencyAccountId: value,
                                            marketingAccountId: xero.config?.marketingAccountId,
                                        })
                                        await integrationsApi.refresh()
                                        setConjAccountIdLoading(false)
                                    }}
                                />
                                <XeroAccountAutocomplete
                                    label="Marketing Account"
                                    value={xero.config?.marketingAccountId}
                                    loading={conjAccountIdLoading}
                                    onChange={async (e, value) => {
                                        setConjAccountIdLoading(true)
                                        await api.post('/xero/updateconfig', {
                                            conjunctionalAgencyAccountId:
                                                xero.config?.conjunctionalAgencyAccountId,
                                            marketingAccountId: value,
                                        })
                                        await integrationsApi.refresh()
                                        setConjAccountIdLoading(false)
                                    }}
                                />
                            </Stack>
                        </>
                    ) : (
                        <Button
                            loading={loading}
                            onClick={() => {
                                setLoading(true)
                                const scopes = [
                                    'offline_access',
                                    'openid',
                                    'profile',
                                    'email',
                                    'accounting.transactions',
                                    'accounting.contacts',
                                    'accounting.settings.read',
                                    'payroll.employees',
                                    //'bankfeeds',
                                ]
                                window.location.href = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${import.meta.env.VITE_XERO_CLIENT_ID}&redirect_uri=${redirect_uri}&scope=${scopes.join(' ')}&state=${orgId}`
                            }}
                        >
                            Connect
                        </Button>
                    )}
                </Box>
            </Stack>
        </Box>
    )
}

const Docusign = () => {
    const api = useApi()
    const { user } = useUserData()
    const { mode } = useColorScheme()
    const integrationsApi = useOrgIntegrationsApi()

    const [loading, setLoading] = useState(false)

    return (
        <>
            {/*import.meta.env.DEV && (
                <Stack gap={2}>
                    <Divider sx={{ my: 4 }} />
                    <Box sx={{ width: '338px' }}>
                        <img src={mode == 'dark' ? docusignLogoWhite : docusignLogoBlack} />
                    </Box>
                    {integrationsApi.data!.docusign ? (
                        <Box>
                            <Typography>
                                Connected to{' '}
                                {
                                    integrationsApi.data!.docusign?.userInfo.accounts.find(
                                        x => x.is_default == true,
                                    )!.account_name
                                }
                            </Typography>
                            <Button
                                loading={loading}
                                variant="outlined"
                                color="danger"
                                onClick={async () => {
                                    setLoading(true)
                                    await api.post('/docusign/disconnect')
                                    await integrationsApi.refresh()
                                    setLoading(false)
                                }}
                                sx={{
                                    mt: 2,
                                }}
                            >
                                Disconnect
                            </Button>
                        </Box>
                    ) : (
                        <Box>
                            <Button
                                variant="outlined"
                                //href={docusignConnectionUrl(user)}
                                onClick={() => {
                                    setLoading(true)
                                    window.location.href = docusignConnectionUrl(user)
                                }}
                            >
                                Connect
                            </Button>
                        </Box>
                    )}
                </Stack>
            )*/}
        </>
    )
}

export default () => {
    const integrationsApi = useOrgIntegrationsApi()

    if (!integrationsApi.data) return <Loading />

    return (
        <>
            <Xero />
            {/*<Docusign />*/}
        </>
    )
}
