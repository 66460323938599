import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { capitalize, toCurrency } from '@fastre/core/src/helperFunctions/string'
import { InternalConjunctionalAgencySchema } from '@fastre/core/src/schemas/conjunionalAgency'
import { InternalTransactionSchema } from '@fastre/core/src/schemas/contract'
import { BalanceSheetSchema } from '@fastre/core/src/schemas/listing'
import { zodResolver } from '@hookform/resolvers/zod'
import { DragHandleRounded, EditRounded } from '@mui/icons-material'
import { Box, Button, CircularProgress, IconButton, Link, Modal, Stack, Table, Typography } from '@mui/joy'
import { useApi } from 'api'
import { useConjunctionalAgenciesApi, useFindUserFromId } from 'apiProviders'
import { useUserData } from 'auth'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useShowSnack } from 'components/snackbar'
import { SortableList } from 'components/sortableList'
import { format } from 'date-fns'
import { useMaybeState } from 'helperFunctions/react'
import { Maybe } from 'monet'
import { pick, prop, propEq, sum } from 'ramda'
import { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { useListingContext } from '../listingProvider'
import ConfirmPaidModal from './confirmPaidModal'
import EditTransactionModal from './editTransactionModal'
import FundDisbursement from './fundDisbursement'

interface RowProps {
    transaction: InternalTransactionSchemaWithBalance
    transactionIndex: number
    setEditTransaction: (transaction: InternalTransactionSchema) => void
    setConfirmTransaction: (val: { transaction: InternalTransactionSchema; transactionIndex: number }) => void
    conjunctionalAgency?: InternalConjunctionalAgencySchema
}

const Row = ({
    transaction,
    transactionIndex,
    setEditTransaction,
    setConfirmTransaction,
    conjunctionalAgency,
}: RowProps) => {
    const api = useApi()
    const { listing } = useListingContext()
    const findUserFromId = useFindUserFromId()
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: transaction.id })
    const { user } = useUserData()

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    const [createInvoiceLoading, setCreateInvoiceLoading] = useState(false)

    return (
        <tr
            ref={setNodeRef}
            style={style}
        >
            <td
                style={{
                    border: 'none',
                    background: 'none',
                }}
            >
                <IconButton
                    {...listeners}
                    {...attributes}
                >
                    <DragHandleRounded />
                </IconButton>
            </td>
            <td>{transaction.date && format(new Date(transaction.date), 'dd/MM/yy')}</td>
            <td>{transaction.description}</td>
            <td align="right">{transaction.type == 'credit' && toCurrency(transaction.amount)}</td>
            <td align="right">{transaction.type == 'debit' && toCurrency(transaction.amount)}</td>
            <td align="right">{toCurrency(transaction.balance)}</td>
            <td align="center">
                {transaction.paid?.userId ? (
                    <>
                        {findUserFromId(transaction.paid.userId)
                            .map(x => (x.firstName[0] + x.lastName[0]).toUpperCase())
                            .orSome('')}
                    </>
                ) : (
                    <Link
                        onClick={() =>
                            setConfirmTransaction({
                                transaction,
                                transactionIndex,
                            })
                        }
                    >
                        {/*transaction.type == 'credit' ? 'Paid' : 'Receipted'*/}
                        Pending
                    </Link>
                )}
            </td>
            {user.permissions.includes('agentStatement.generatePayroll') && conjunctionalAgency && (
                <td>
                    {transaction.externalRef == 'marketing' &&
                        (transaction.xeroInvoice ? (
                            <Link onClick={() => window.open(transaction.xeroInvoice.url, '_blank')}>
                                Invoiced
                            </Link>
                        ) : createInvoiceLoading ? (
                            <CircularProgress sx={{ '--CircularProgress-size': '20px' }} />
                        ) : (
                            <Link
                                onClick={async () => {
                                    setCreateInvoiceLoading(true)
                                    try {
                                        await api.post(`/listing/sale/${listing.listingId}/xero/rcti`, {
                                            transactionId: transaction.id,
                                        })
                                    } catch (e) {
                                        console.error(e)
                                    } finally {
                                        setCreateInvoiceLoading(false)
                                    }
                                }}
                                disabled={conjunctionalAgency?.xero == undefined}
                            >
                                Create Invoice
                            </Link>
                        ))}
                    {transaction.externalRef == 'agentCommission' &&
                        (transaction.xeroInvoice ? (
                            <Link onClick={() => window.open(transaction.xeroInvoice.url, '_blank')}>
                                Invoiced
                            </Link>
                        ) : (
                            <>
                                {createInvoiceLoading ? (
                                    <CircularProgress sx={{ '--CircularProgress-size': '20px' }} />
                                ) : (
                                    <Link
                                        onClick={async () => {
                                            setCreateInvoiceLoading(true)
                                            try {
                                                await api.post(
                                                    `/listing/sale/${listing.listingId}/xero/rcti`,
                                                    {
                                                        transactionId: transaction.id,
                                                    },
                                                )
                                            } catch (e) {
                                                console.error(e)
                                            } finally {
                                                setCreateInvoiceLoading(false)
                                            }
                                        }}
                                        disabled={conjunctionalAgency?.xero == undefined}
                                    >
                                        Create Invoice
                                    </Link>
                                )}
                            </>
                        ))}
                </td>
            )}
            <td>
                <Box>
                    <IconButton
                        size="sm"
                        onClick={() => {
                            setEditTransaction(transaction)
                        }}
                    >
                        <EditRounded fontSize="small" />
                    </IconButton>
                </Box>
            </td>
        </tr>
    )
}

type InternalTransactionSchemaWithBalance = InternalTransactionSchema & { balance: number }

export default () => {
    const { user } = useUserData()
    const api = useApi()
    const { listing, setListing } = useListingContext()
    const showSnack = useShowSnack()
    const conjunctionalAgenciesApi = useConjunctionalAgenciesApi()
    const findUserFromId = useFindUserFromId()

    const agent = findUserFromId(listing.agentUserId)
    const conjunctionalAgency = agent
        .bind(a =>
            Maybe.fromUndefined(
                a.conjunctionalAgencyId
                    ? conjunctionalAgenciesApi.data?.find(
                          propEq('conjunctionalAgencyId', a.conjunctionalAgencyId),
                      )
                    : undefined,
            ),
        )
        .orUndefined()

    const [editTransaction, setEditTransaction] = useMaybeState<any>()
    const [confirmTransaction, setConfirmTransaction] = useMaybeState<{
        transaction: InternalTransactionSchema
        transactionIndex: number
    }>()

    const hookForm = useForm<BalanceSheetSchema>({
        defaultValues: {
            balanceSheet: [],
        },
        resolver: zodResolver(BalanceSheetSchema),
    })

    const { control, handleSubmit, formState, getValues, setValue, trigger, reset, watch } = hookForm

    useEffect(() => {
        reset(pick(['balanceSheet'], listing))
    }, [listing.balanceSheet])

    const {
        fields: balanceSheet,
        append: appendBalance,
        remove: removeBalance,
    } = useFieldArray({
        control,
        name: 'balanceSheet',
    })
    const balanceSheetWatch = watch('balanceSheet') as InternalTransactionSchema[]

    const [loading, setLoading] = useState(false)

    const onSubmit: SubmitHandler<BalanceSheetSchema> = async data => {
        setLoading(true)
        try {
            const updatedListing = await api
                .post(`/listing/sale/${listing.listingId}/updatebalancesheet`, data)
                .then(prop('data'))
            setListing(updatedListing)
            reset(pick(['balanceSheet'], updatedListing))
            showSnack('Trust fund saved', 'success')
        } catch (e) {
            console.error(e)
            showSnack('Error saving trust fund', 'danger')
        } finally {
            setLoading(false)
        }
    }

    const balanceSheetWatch_withBalance = balanceSheetWatch.reduce(
        (acc: InternalTransactionSchemaWithBalance[], transaction: InternalTransactionSchema) => [
            ...acc,
            {
                ...transaction,
                balance:
                    (acc[acc.length - 1]?.balance ?? 0) +
                    transaction.amount * (transaction.type == 'credit' ? 1 : -1),
            } as any,
        ],
        [] as InternalTransactionSchemaWithBalance[],
    ) as InternalTransactionSchemaWithBalance[]

    return (
        <>
            <FormProvider {...hookForm}>
                <form
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Stack gap={2}>
                        {listing.marketingPaymentMethod && (
                            <Box>
                                <Typography>
                                    Marketing package: $
                                    <b>
                                        {sum(
                                            listing.marketingPackage?.packageItems.map(prop('itemPrice')) ?? [
                                                0,
                                            ],
                                        )}
                                    </b>
                                </Typography>
                                <Typography>
                                    Payment method: <b>{capitalize(listing.marketingPaymentMethod)}</b>
                                </Typography>
                                {listing.marketingPaymentStatus && (
                                    <Typography>
                                        Payment status:{' '}
                                        <b>{capitalize(listing.marketingPaymentStatus.paymentStatus)}</b>
                                    </Typography>
                                )}
                            </Box>
                        )}

                        <Table borderAxis="both">
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            width: '60px',
                                            border: 'none',
                                            background: 'none',
                                        }}
                                    />
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th align="right">Credit</th>
                                    <th align="right">Debit</th>
                                    <th align="right">Balance</th>
                                    <th align="center">Paid/Receipted</th>
                                    {user.permissions.includes('agentStatement.generatePayroll') &&
                                        conjunctionalAgency && <th />}
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                <SortableList
                                    items={balanceSheetWatch_withBalance}
                                    onChange={newItems => {
                                        setValue('balanceSheet', newItems)
                                    }}
                                    renderItem={(transaction, transactionIndex) => (
                                        <Row
                                            key={transaction.id}
                                            transaction={transaction}
                                            transactionIndex={transactionIndex}
                                            setEditTransaction={setEditTransaction}
                                            setConfirmTransaction={setConfirmTransaction}
                                            conjunctionalAgency={conjunctionalAgency}
                                        />
                                    )}
                                />
                            </tbody>
                        </Table>
                        {user.permissions.includes('fit.edit') && (
                            <>
                                <Box sx={{ mt: 2 }}>
                                    <Button
                                        onClick={() => {
                                            setEditTransaction({
                                                //date: Date.now(),
                                                //description: '',
                                                //amount: 0,
                                                //type: 'credit',
                                            })
                                        }}
                                        variant="outlined"
                                    >
                                        Add Transaction
                                    </Button>
                                </Box>
                                <Box sx={{ mt: 4, mb: 4 }}>
                                    <Button
                                        loading={loading}
                                        type="submit"
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </>
                        )}
                        {user.permissions.includes('fundDisbursement.send') &&
                            (import.meta.env.VITE_APP_STAGE == 'uat' || import.meta.env.DEV) && (
                                <FundDisbursement />
                            )}
                    </Stack>
                </form>
            </FormProvider>
            <Modal
                open={editTransaction.isSome()}
                onClose={dontCloseOnBackgroundClick(() => setEditTransaction(undefined))}
            >
                {editTransaction
                    .map(transaction => (
                        <EditTransactionModal
                            transaction={transaction}
                            close={() => setEditTransaction(undefined)}
                            save={(data: InternalTransactionSchema) => {
                                if (transaction.id) {
                                    const index = balanceSheetWatch.findIndex(x => x.id == transaction.id)
                                    console.log('index', index)
                                    setValue(`balanceSheet.${index}`, data)
                                } else {
                                    appendBalance(data)
                                }
                            }}
                            onDelete={() =>
                                removeBalance(balanceSheetWatch.findIndex(x => x.id == transaction.id))
                            }
                        />
                    ))
                    .orSome(<></>)}
            </Modal>
            <Modal
                open={confirmTransaction.isSome()}
                onClose={(event, reason) => {
                    if (reason === 'backdropClick') {
                        return
                    }
                    setConfirmTransaction(undefined)
                }}
            >
                {confirmTransaction
                    .map(({ transaction, transactionIndex }) => (
                        <ConfirmPaidModal
                            key={transactionIndex}
                            transaction={transaction}
                            close={(confirm, date) => {
                                if (confirm) {
                                    setValue(`balanceSheet.${transactionIndex}`, {
                                        ...transaction,
                                        date,
                                        paid: {
                                            time: Date.now(),
                                            userId: user.userId,
                                        },
                                    })
                                }
                                setConfirmTransaction(undefined)
                            }}
                        />
                    ))
                    .orSome(<></>)}
            </Modal>
        </>
    )
}
