import { toCurrency } from '@fastre/core/src/helperFunctions/string'
import { getStatementDescription, InternalAgentStatement } from '@fastre/core/src/schemas/agentStatement'
import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    Modal,
    ModalClose,
    ModalDialog,
    Table,
    Typography,
} from '@mui/joy'
import { useApi } from 'api'
import { useAgentStatementApi } from 'apiProviders'
import Input from 'components/input'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useShowSnack } from 'components/snackbar'
import { format } from 'date-fns'
import { prop, sortBy, sum } from 'ramda'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router'

interface Props {
    items: InternalAgentStatement[]
    onClose: () => void
}

export default function GeneratePayroll({ items, onClose }: Props) {
    const api = useApi()
    const showSnack = useShowSnack()
    const { userId } = useParams<{ userId: string }>()
    const agentStatementApi = useAgentStatementApi(userId!)
    const payrollApi = useAgentStatementApi(userId!, false)

    const [payrollDate, setPayrollDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [selected, setSelected] = useState<string[]>(items.map(prop('id')))
    const numSelected = selected.length
    const rowCount = items.length
    const [Loading, setLoading] = useState(false)

    const selectedItems = useMemo(() => items.filter(item => selected.includes(item.id)), [items, selected])

    const debits = sum(selectedItems.filter(item => item.type == 'debit').map(prop('amount')))
    const credits = sum(selectedItems.filter(item => item.type == 'credit').map(prop('amount')))
    const total = credits - debits

    return (
        <Modal
            open={true}
            onClose={dontCloseOnBackgroundClick(onClose)}
        >
            <ModalDialog sx={{ width: '100%' }}>
                <ModalClose />
                <DialogTitle>Process Payroll</DialogTitle>
                <DialogContent>
                    <Input
                        label="Date"
                        type="date"
                        value={payrollDate}
                        onChange={setPayrollDate}
                        sx={{ width: '160px', my: 2 }}
                    />

                    <Table>
                        <thead>
                            <tr>
                                <th>
                                    <Checkbox
                                        indeterminate={numSelected > 0 && numSelected < rowCount}
                                        checked={rowCount > 0 && numSelected === rowCount}
                                        onChange={() =>
                                            setSelected(
                                                selected.length === rowCount ? [] : items.map(prop('id')),
                                            )
                                        }
                                        sx={{ verticalAlign: 'sub' }}
                                    />
                                </th>
                                <th>Date</th>
                                <th>Description</th>
                                <th>Category</th>
                                <th style={{ textAlign: 'right' }}>Debit</th>
                                <th style={{ textAlign: 'right' }}>Credit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortBy(x => x.date, items).map(item => (
                                <tr key={item.id}>
                                    <td scope="row">
                                        <Checkbox
                                            checked={selected.includes(item.id)}
                                            sx={{ verticalAlign: 'top' }}
                                            onChange={e =>
                                                setSelected(
                                                    e.target.checked
                                                        ? [...selected, item.id]
                                                        : selected.filter(id => id !== item.id),
                                                )
                                            }
                                        />
                                    </td>
                                    <td>{format(item.date, 'dd/MM/yy')}</td>
                                    <td>{getStatementDescription(item)}</td>
                                    <td>{item.type}</td>
                                    <td style={{ textAlign: 'right' }}>
                                        {item.type == 'debit' && toCurrency(item.amount)}
                                    </td>
                                    <td style={{ textAlign: 'right' }}>
                                        {item.type == 'credit' && toCurrency(item.amount)}
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td />
                                <td />
                                <td />
                                <td>
                                    <Typography level="h4">Totals:</Typography>
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    <Typography level="h4">{toCurrency(debits)}</Typography>
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    <Typography level="h4">{toCurrency(credits)}</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td />
                                <td />
                                <td />
                                <td>
                                    <Typography level="h3">Balance:</Typography>
                                </td>
                                <td
                                    colSpan={2}
                                    style={{ textAlign: 'right' }}
                                >
                                    <Typography level="h3">{toCurrency(total)}</Typography>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button
                        loading={Loading}
                        disabled={selected.length === 0 || total < 0}
                        onClick={async () => {
                            setLoading(true)
                            try {
                                await api.post('/generatepayroll', {
                                    userId,
                                    statementItemIds: selected,
                                    paySlipDate: payrollDate,
                                })
                                await Promise.all([agentStatementApi.refresh(), payrollApi.refresh()])
                                showSnack('Payroll generated', 'success')
                                onClose()
                            } catch (e) {
                                showSnack('Failed to generate payroll', 'danger')
                            } finally {
                                setLoading(false)
                            }
                        }}
                    >
                        Process
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}
