import { TransactionSchema } from '@fastre/core/src/schemas/contract'
import { zodResolver } from '@hookform/resolvers/zod'
import { DeleteRounded } from '@mui/icons-material'
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    ModalClose,
    ModalDialog,
    Option,
    Select,
    Stack,
} from '@mui/joy'
import { useUserData } from 'auth'
import { SlotAutocomplete } from 'components/autocomplete'
import { SlotInput } from 'components/input'
import { useShowSnack } from 'components/snackbar'
import { omit } from 'ramda'
import { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { v4 as uuid } from 'uuid'

const EditTransactionModal = ({
    transaction,
    close,
    save,
    onDelete,
}: {
    transaction: TransactionSchema
    close
    save
    onDelete
}) => {
    const showSnack = useShowSnack()
    const { user } = useUserData()

    const { register, control, handleSubmit, formState, getValues, setValue, trigger, watch } =
        useForm<TransactionSchema>({
            defaultValues: {
                id: transaction.id ?? uuid(),
                ...omit(['id'], transaction),
            },
            resolver: zodResolver(TransactionSchema),
        })
    const [loading, setLoading] = useState(false)

    const isNew = transaction.id == undefined

    const onSubmit: SubmitHandler<TransactionSchema> = async data => {
        setLoading(true)
        try {
            save(data)
            close()
        } catch (e) {
            console.error(e)
            showSnack('Error saving listing', 'danger')
        } finally {
            setLoading(false)
        }
    }

    const description = watch('description')

    return (
        <ModalDialog>
            <ModalClose />
            <DialogTitle>{isNew ? 'Add' : 'Edit'} Transaction</DialogTitle>
            <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >
                <DialogContent sx={{ width: '320px' }}>
                    <Stack spacing={1}>
                        {/*<Controller
                            name="date"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Date (optional)"
                                    type="date"
                                    {...field}
                                />
                            )}
                        />*/}
                        <Controller
                            name="description"
                            control={control}
                            render={field => (
                                <SlotAutocomplete
                                    label="Description"
                                    options={['Deposit', 'Vendor Balance', 'Marketing', 'Office Commission']}
                                    freeSolo
                                    {...field}
                                    onChange={(e, value) => {
                                        field.field.onChange(value)
                                        if (['Deposit', 'Vendor Balance'].includes(value as any)) {
                                            setValue('type', 'credit')
                                        } else {
                                            setValue('type', 'debit')
                                        }
                                    }}
                                    onBlur={(e: any) => field.field.onChange(e.target.value)}
                                />
                            )}
                        />

                        <Controller
                            name="type"
                            control={control}
                            render={field => (
                                <FormControl sx={{ flex: 1 }}>
                                    <FormLabel>Transaction Type</FormLabel>
                                    <Select
                                        {...field.field}
                                        onChange={(e, value) => field.field.onChange(value)}
                                    >
                                        <Option value="credit">Credit</Option>
                                        <Option value="debit">Debit</Option>
                                    </Select>
                                </FormControl>
                            )}
                        />
                        <Controller
                            name="amount"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Amount"
                                    type="dollar"
                                    startDecorator="$"
                                    {...field}
                                />
                            )}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        loading={loading}
                    >
                        Save
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={close}
                    >
                        Cancel
                    </Button>
                    {!isNew && (transaction.externalRef == undefined || user.superUser) && (
                        <Button
                            color="danger"
                            variant="outlined"
                            sx={{ mr: 'auto' }}
                            onClick={() => {
                                onDelete()
                                close()
                            }}
                            loading={loading}
                            startDecorator={<DeleteRounded />}
                        >
                            Delete
                        </Button>
                    )}
                </DialogActions>
            </form>
        </ModalDialog>
    )
}

export default EditTransactionModal
