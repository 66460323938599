import { InternalLedgerAuditLogSchema } from '@fastre/core/src/schemas/ledger'
import { Table } from '@mui/joy'
import { useApi } from 'api'
import { useFindUserFromId, useLedgerMonth } from 'apiProviders'
import LedgerMonthSelector from 'components/ledgerMonthSelector'
import { format } from 'date-fns'
import { prop, sortBy } from 'ramda'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

const LedgerAuditLog = () => {
    const api = useApi()
    const findUser = useFindUserFromId()
    const { userId } = useParams<{ userId: string }>()
    const { month } = useLedgerMonth()

    const [auditLog, setAuditLog] = useState<InternalLedgerAuditLogSchema[]>([])
    useEffect(() => {
        api.post('/ledger/getauditlog', { userId, month }).then(({ data }) => setAuditLog(data))
    }, [userId, month])

    return (
        <>
            <LedgerMonthSelector />
            <Table>
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {sortBy(prop('timestamp'), auditLog).map(log => (
                        <tr key={log.id}>
                            <td>
                                {findUser(log.actionByUserId)
                                    .map(user => `${user.firstName} ${user.lastName}`)
                                    .getOrElse('')}
                            </td>
                            <td>{format(log.timestamp, 'dd/MM/yy hh:mm')}</td>
                            <td
                                style={{
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {log.action}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    )
}

export default LedgerAuditLog
