import { capitalize } from '@fastre/core/src/helperFunctions/string'
import { getCommissionCalculatedPrice, ListingType } from '@fastre/core/src/schemas/listing'
import {
    InternalSaleListingSchema,
    listingEnabledForUser,
    UpdateSaleListingSchema,
} from '@fastre/core/src/schemas/saleListing'
import { VendorType } from '@fastre/core/src/schemas/vendor'
import { zodResolver } from '@hookform/resolvers/zod'
import { Delete, Edit, Save } from '@mui/icons-material'
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    IconButton,
    Modal,
    Option,
    Radio,
    RadioGroup,
    Select,
    Sheet,
    Stack,
    Switch,
    Textarea,
    Typography,
} from '@mui/joy'
import { useApi } from 'api'
import { useListingConfigApi, useListingsApi, useUsersApi } from 'apiProviders'
import { useUserData } from 'auth'
import Loading from 'components/Loading'
import LocationAutocomplete from 'components/LocationAutocomplete'
import AgentInput from 'components/agentInput'
import Input, { SlotInput, SlotWrapper } from 'components/input'
import { useShowSnack } from 'components/snackbar'
import { MobileFriendlyStack } from 'components/stack'
import UnsavedModal from 'components/unsavedModal'
import { addDays, format } from 'date-fns'
import { saveAs } from 'file-saver'
import { useMaybeState } from 'helperFunctions/react'
import { dissoc, omit, prop, sum } from 'ramda'
import { useEffect, useState } from 'react'
import {
    Controller,
    FormProvider,
    SubmitHandler,
    useFieldArray,
    useForm,
    useFormContext,
} from 'react-hook-form'
import { useBlocker, useNavigate } from 'react-router'
import { RenderListingValues } from '../additionalValues'
import FormStepper from '../formStepper'
import { useListingStatusOptions } from '../helpers'
import { useListingContext } from '../listingProvider'
import MarketingPackageModal from '../marketingPackageModal'
import RelloMarketingPayment from '../relloMarketingPaymentSection'
import VendorList from '../vendorList'
import CreateXeroMarketingInvoiceButton from './createXeroMarketingInvoiceButton'

interface QbccInfo {
    'Number of Pools': string
    'Shared Pool Property': string
    'Site Name': string
    'Street Name': string
    'Street Number': string
    'Street Type': string
    Suburb: string
    'Unit Number': string | undefined
    rank: number
}

const AdditionalChargeItem = ({
    charge,
    index,
    formDisabled,
    removeAdditionalCharge,
}: {
    charge: any
    index: number
    formDisabled: boolean
    removeAdditionalCharge: (index: number) => void
}) => {
    const { control, setValue, watch } = useFormContext()

    const payType = watch(`additionalCharges.${index}.payType`)

    return (
        <MobileFriendlyStack key={charge.id}>
            <Controller
                name={`additionalCharges.${index}.description`}
                disabled={formDisabled}
                control={control}
                render={field => (
                    <SlotInput
                        label="Description"
                        {...field}
                        formControlProps={{ sx: { minWidth: '160px' } }}
                    />
                )}
            />
            <Controller
                name={`additionalCharges.${index}.amount`}
                disabled={formDisabled}
                control={control}
                render={field => (
                    <SlotInput
                        label="Amount"
                        type="dollar"
                        {...field}
                        formControlProps={{ sx: { minWidth: '160px' } }}
                    />
                )}
            />
            <Controller
                name={`additionalCharges.${index}.payType`}
                control={control}
                render={field => (
                    <FormControl>
                        <FormLabel>Pay on</FormLabel>
                        <Switch
                            {...dissoc('value', field.field)}
                            checked={field.field.value == 'settlement'}
                            onChange={e =>
                                setValue(
                                    `additionalCharges.${index}.payType`,
                                    e.target.checked ? 'settlement' : 'now',
                                )
                            }
                            startDecorator={({ checked }) => (
                                <Typography
                                    level="body-sm"
                                    sx={{ opacity: !checked ? 1 : 0.35 }}
                                >
                                    Date
                                </Typography>
                            )}
                            endDecorator={({ checked }) => (
                                <Typography
                                    level="body-sm"
                                    sx={{ opacity: checked ? 1 : 0.35 }}
                                >
                                    Settlement
                                </Typography>
                            )}
                        />
                    </FormControl>

                    /*<FormControl>
                                            <FormLabel>Pay on</FormLabel>
                                            <RadioGroup value={field.field.value}>
                                                <Radio
                                                    value="now"
                                                    label="Now"
                                                    size="sm"
                                                />
                                                <Radio
                                                    value="settlememt"
                                                    label="Settlment"
                                                    size="sm"
                                                />
                                            </RadioGroup>
                                            </FormControl>*/
                    /*
                                            <Checkbox
                                                label="Pay on Settlement"
                                                {...field.field}
                                                checked={field.field.value == 'settlement'}
                                                onChange={e =>
                                                    setValue(
                                                        `additionalCharges.${i}.payType`,
                                                        e.target.checked ? 'settlement' : 'now',
                                                    )
                                                }
                                                sx={{
                                                    alignItems: 'center',
                                                    height: '100%',
                                                }}
                                                
                                            />
                                        </FormControl>*/
                )}
            />
            <Controller
                name={`additionalCharges.${index}.payable`}
                disabled={formDisabled}
                control={control}
                render={field =>
                    payType == 'settlement' ? (
                        <Input
                            label="Payable Date"
                            value="On settlement"
                            readOnly
                            disabled
                            formControlProps={{ sx: { width: '160px ' } }}
                        />
                    ) : (
                        <SlotInput
                            label="Payable Date"
                            type="date"
                            {...field}
                            formControlProps={{ sx: { width: '160px' } }}
                        />
                    )
                }
            />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                }}
            >
                <IconButton
                    variant="outlined"
                    disabled={formDisabled}
                    onClick={() => removeAdditionalCharge(index)}
                    //size="sm"
                >
                    <Delete /*fontSize="small"*/ />
                </IconButton>
            </Box>
        </MobileFriendlyStack>
    )
}

const CoreListingDetails = ({ type }: { type: ListingType }) => {
    const api = useApi()
    const { listing, setListing } = useListingContext()
    const showSnack = useShowSnack()
    const navigate = useNavigate()
    const listingsApi = useListingsApi(false)
    const listingConfigApi = useListingConfigApi()
    const usersApi = useUsersApi()
    const listingStatusOptions = useListingStatusOptions(listing)

    const isNew = listing.listingId == undefined
    const { user } = useUserData()

    const [loading, setLoading] = useState(false)
    const [vendorType, setVendorType] = useState<VendorType>(
        listing.vendors ? (listing.vendors[0]?.vendorType ?? 'individual') : 'individual',
    )

    const enabledForUser = listingEnabledForUser(listing, usersApi.data, user)

    const formEnabled =
        isNew ||
        user.permissions.includes('listings.fullControl') ||
        (listing.agentAppointmentForm == undefined && enabledForUser)

    const formDisabled = !formEnabled

    const formFullyDisabled =
        !isNew &&
        !user.permissions.includes('listings.fullControl') &&
        !(enabledForUser && user.permissions.includes('listings.edit'))

    const [maybeQbccInfo, setQbccInfo] = useMaybeState<QbccInfo>()
    const [maybePoolCertificate, setPoolCertificate] = useMaybeState<string | boolean>()
    const [showMarketingPackageModal, setShowMarketingPackageModal] = useState(false)

    const hookForm = useForm<UpdateSaleListingSchema>({
        defaultValues: {
            authorityPeriod: 90,
            listingType: 'sale',
            commission: (listing as InternalSaleListingSchema).commission ?? 2.5,
            marketingPaymentMethod: 'rello',
            ...omit(['contract', 'vendors'], listing),
            occupantStatus: (listing as InternalSaleListingSchema).occupantStatus ?? 'owner occupied',
            vendors:
                listing.vendors?.map(({ vendorType, ...v }) => ({
                    ...v,
                    vendorType: vendorType ?? 'individual',
                })) ?? [],
        },
        resolver: zodResolver(UpdateSaleListingSchema),
    })

    const [ocrLoading, setOcrLoading] = useState(false)

    const { control, handleSubmit, formState, getValues, setValue, setError, trigger, reset, watch } =
        hookForm

    useEffect(() => {
        reset(listing as any)
    }, [listing.listingId])

    const {
        fields: vendors,
        append: appendVendor,
        remove: removeVendor,
    } = useFieldArray({
        control,
        name: 'vendors',
    })

    const {
        fields: additionalCharges,
        append: appendAdditionalCharge,
        remove: removeAdditionalCharge,
    } = useFieldArray({
        control,
        name: 'additionalCharges',
    })

    const listingType = watch('listingType')
    const authorityStartDate = watch('authorityStartDate')
    const authorityPeriod = watch('authorityPeriod')
    const advertisedPrice = watch('advertisedPrice')
    const commission = watch('commission')
    const searchPriceType = watch('hiddenPrice.type')
    const marketingPackage = watch('marketingPackage')
    const occupantStatus = watch('occupantStatus')
    const marketingPaymentMethod = watch('marketingPaymentMethod')
    const hiddenPrice = watch('hiddenPrice')
    const sensitiveInfo = watch('sensitiveInfo')

    useEffect(() => {
        setValue('listingStatus', listing.listingStatus, { shouldDirty: false })
    }, [listing.listingStatus])

    const commissionCalculatedPrice = getCommissionCalculatedPrice({
        advertisedPrice,
        hiddenPrice,
    })

    if (Object.keys(formState.errors).length > 0) {
        console.log('errors', formState.errors)
    }

    useEffect(() => {
        console.log('isDirty', formState.isDirty)
        console.log('dirtyFields', formState.dirtyFields)
    }, [formState.isDirty, JSON.stringify(formState.dirtyFields)])

    const onSubmit: SubmitHandler<UpdateSaleListingSchema> = async data => {
        setLoading(true)
        try {
            if (isNew) {
                const { listingId } = await api.post(`/listing/${type}/create`, data).then(prop('data'))
                navigate(`../../${listingId}`, {
                    relative: 'path',
                    replace: true,
                })
            } else {
                const updatedListing = await api
                    .post(`/listing/${type}/${listing.listingId}/update`, data)
                    .then(prop('data'))
                setListing(updatedListing)
                reset(updatedListing)
            }
            listingsApi.refreshSpecific(listing.listingId)
            showSnack('Listing saved', 'success')
        } catch (e) {
            console.error(e)
            showSnack('Error saving listing', 'danger')
        } finally {
            setLoading(false)
        }
    }

    const handleSubmitPromise = (): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            handleSubmit(
                async data => {
                    try {
                        await onSubmit(data)
                        resolve(true)
                    } catch (error) {
                        resolve(false)
                    }
                },
                error => {
                    resolve(false)
                },
            )()
        })
    }

    const saveForm = async (): Promise<boolean> => {
        if (formDisabled) {
            setLoading(true)
            try {
                const updatedListing = await api
                    .post(`/listing/${type}/${listing.listingId}/update`, {
                        ...listing,
                        agentUserId: listing.agentUserId,
                        listingStatus: getValues('listingStatus'),
                        additionalDetails: getValues('additionalDetails'),
                        sensitiveInfo: getValues('sensitiveInfo'),
                    })
                    .then(prop('data'))
                setListing(updatedListing)
                reset(updatedListing)
                listingsApi.refreshSpecific(listing.listingId)
                showSnack('Listing saved', 'success')
                return true
            } catch (e) {
                console.error(e)
                showSnack('Error saving listing', 'danger')
                return false
            } finally {
                setLoading(false)
            }
        } else {
            return await handleSubmitPromise()
        }
    }

    const blocker = useBlocker(({ currentLocation, nextLocation }) => {
        //return false
        console.log(formState.dirtyFields)
        console.log('listing', listing.listingId)

        return (
            !isNew &&
            currentLocation.pathname !== nextLocation.pathname &&
            formState.isDirty &&
            Object.keys(formState.dirtyFields).length > 0
        )
    })

    useEffect(() => {
        listingConfigApi.maybeData.forEach(configs => {
            configs.forEach(config =>
                setValue(
                    `additionalDetails.${config.id}`,
                    listing.additionalDetails?.[config.id] ?? config.configValues[0].value,
                ),
            )
        })
    }, [listingConfigApi.maybeData.isSome()])

    if (listingConfigApi.maybeData.isNone()) {
        return <Loading />
    }

    return (
        <FormProvider {...hookForm}>
            <form
                noValidate
                //onSubmit={handleSubmit(onSubmit)}
            >
                <Stack gap={2}>
                    <MobileFriendlyStack>
                        <Controller
                            name="listingAddress"
                            control={control}
                            render={field => (
                                <LocationAutocomplete
                                    label="Address"
                                    {...field}
                                    value={(field.field.value as any) ?? null}
                                    onChange={(loc, geo) => {
                                        field.field.onChange(loc)
                                        setValue('geoLocation', geo)
                                    }}
                                    slotProps={{
                                        input: {
                                            tabIndex: 0,
                                        },
                                    }}
                                    autoComplete={false}
                                    name="donotautofill"
                                    id="donotautofill"
                                />
                            )}
                            disabled={formDisabled}
                        />
                        <Controller
                            name="propertyType"
                            control={control}
                            disabled={formDisabled}
                            render={field => (
                                <FormControl>
                                    <FormLabel>Contract</FormLabel>
                                    <RadioGroup
                                        orientation="horizontal"
                                        {...field.field}
                                        onChange={e => field.field.onChange(e.target.value)}
                                    >
                                        <Radio
                                            value="residential"
                                            label="House & Land"
                                            disabled={formDisabled}
                                        />
                                        <Radio
                                            value="cts"
                                            label="CTS"
                                            disabled={formDisabled}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            )}
                        />
                    </MobileFriendlyStack>
                    <MobileFriendlyStack>
                        <Controller
                            name="listingStatus"
                            control={control}
                            disabled={formFullyDisabled}
                            render={({ field: { ref, ...field } }) => {
                                return (
                                    <FormControl sx={{ flex: 1 }}>
                                        <FormLabel>Status</FormLabel>
                                        <Select
                                            {...field}
                                            onChange={(e, value) => field.onChange(value)}
                                            slotProps={{
                                                listbox: {
                                                    ref,
                                                    tabIndex: 0,
                                                },
                                            }}
                                        >
                                            {listingStatusOptions.map(({ value, disabled }) => (
                                                <Option
                                                    key={value}
                                                    value={value}
                                                    disabled={disabled}
                                                >
                                                    {capitalize(value)}
                                                </Option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )
                            }}
                        />
                        <Controller
                            name="agentUserId"
                            control={control}
                            disabled={formDisabled}
                            render={field => (
                                <SlotWrapper
                                    {...field}
                                    label="Agent"
                                >
                                    <AgentInput
                                        {...field.field}
                                        disabled={formDisabled}
                                        //filter={propEq('role', 'sales agent')}
                                    />
                                </SlotWrapper>
                            )}
                        />
                    </MobileFriendlyStack>
                    <VendorList
                        name="Vendor"
                        showAlert={(sensitiveInfo?.replaceAll(' ', '').length ?? 0) > 0}
                        disabled={formDisabled}
                        vendors={vendors}
                        removeVendor={removeVendor}
                        vendorType={vendorType}
                        setVendorType={setVendorType}
                        showOccupantStatus={true}
                        extraFields={(vendor, i) =>
                            occupantStatus == 'investment' &&
                            vendorType == 'individual' && (
                                <Controller
                                    name={`vendors.${i}.address`}
                                    control={control}
                                    render={field => (
                                        <LocationAutocomplete
                                            label="Address"
                                            {...field}
                                            value={(field.field.value as any) ?? null}
                                            onChange={field.field.onChange}
                                            slotProps={{
                                                input: {
                                                    tabIndex: 0,
                                                },
                                            }}
                                        />
                                    )}
                                />
                            )
                        }
                    />
                    {vendors.length < 6 && !formDisabled && (
                        <Box>
                            <Button
                                variant="outlined"
                                onClick={() =>
                                    appendVendor({
                                        vendorType: vendorType as any,
                                        firstName: '',
                                        lastName: '',
                                        email: '',
                                        mobileNumber: '',
                                        abn: '',
                                    })
                                }
                            >
                                Add Vendor
                            </Button>
                        </Box>
                    )}
                    <MobileFriendlyStack>
                        <Controller
                            name="lotNumber"
                            disabled={formDisabled}
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Lot Number"
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="planNumber"
                            disabled={formDisabled}
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Plan Number"
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="titleReference"
                            disabled={formDisabled}
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Title Reference"
                                    {...field}
                                />
                            )}
                        />
                    </MobileFriendlyStack>
                    {/*<Box
                        sx={{
                            display: 'flex', justifyContent: 'flex-end'
                        }}
                    >
                        <Box sx={{ mb: 2 }}>
                            <Button
                                disabled
                                color='success'
                                loading={loadingCL}
                                onClick={async () => {
                                    setLoadingCL(true)
                                    try {
                                        const { data } = await api.post('/corelogic/propertyfromaddress', {
                                            address: listing.listingAddress
                                        })
                                        console.log('data', data)

                                        if (data.legal.parcels.length == 1) {
                                            setValue('lotNumber', data.legal.parcels[0].lot)
                                            setValue('planNumber', data.legal.parcels[0].plan)
                                            setValue('titleReference', data.legal.title.titleReference)
                                        }
                                    } catch (e) {
                                        showSnack('Error getting CoreLogic info', 'danger')
                                    } finally {
                                        setLoadingCL(false)
                                    }
                                }}
                            >
                                Get Core Logic Info
                            </Button>
                            <Typography>*Not available until prod</Typography>
                        </Box>
                    </Box>*/}
                    {type == 'sale' && (
                        <>
                            <MobileFriendlyStack>
                                <Controller
                                    name="listingType"
                                    disabled={formDisabled}
                                    control={control}
                                    render={field => (
                                        <SlotWrapper
                                            {...field}
                                            label="Listing Type"
                                        >
                                            <RadioGroup orientation="horizontal">
                                                <Radio
                                                    value="sale"
                                                    label="Sale"
                                                    checked={field.field.value == 'sale'}
                                                    disabled={formDisabled}
                                                />
                                                <Radio
                                                    value="auction"
                                                    label="Auction"
                                                    checked={field.field.value == 'auction'}
                                                    disabled={formDisabled}
                                                />
                                            </RadioGroup>
                                        </SlotWrapper>
                                    )}
                                />
                                {listingType == 'auction' && (
                                    <Controller
                                        name="auctionDate"
                                        disabled={formDisabled}
                                        control={control}
                                        render={field => (
                                            <SlotInput
                                                {...field}
                                                label="Auction Date"
                                                type="date"
                                            />
                                        )}
                                    />
                                )}
                            </MobileFriendlyStack>
                            <MobileFriendlyStack>
                                <Controller
                                    name="commission"
                                    disabled={formDisabled}
                                    control={control}
                                    render={field => (
                                        <SlotInput
                                            label="Commission Rate Inclusive"
                                            type="number"
                                            {...field}
                                            endDecorator="%"
                                            formControlProps={{
                                                sx: { flex: 1 },
                                            }}
                                            slotProps={{
                                                input: {
                                                    step: 0.1,
                                                },
                                            }}
                                        />
                                    )}
                                />
                                <Box flex={1} />
                            </MobileFriendlyStack>
                            <MobileFriendlyStack>
                                <Controller
                                    name="advertisedPrice"
                                    disabled={formDisabled}
                                    control={control}
                                    render={field => (
                                        <SlotInput
                                            label={listingType == 'auction' ? 'Reserve' : 'Advertised Price'}
                                            {...field}
                                            formControlProps={{
                                                sx: { flex: 1 },
                                            }}
                                            //startDecorator="$"
                                            //type="dollar"
                                        />
                                    )}
                                />
                                <Input
                                    label="Est Commission Amount Inc"
                                    value={
                                        commissionCalculatedPrice && commission
                                            ? Math.round((commissionCalculatedPrice * commission) / 100)
                                            : ''
                                    }
                                    readOnly
                                    disabled
                                    formControlProps={{
                                        sx: { flex: 1 },
                                    }}
                                    startDecorator="$"
                                    type="dollar"
                                />
                            </MobileFriendlyStack>
                            <Controller
                                name="hiddenPrice.type"
                                disabled={formDisabled}
                                control={control}
                                render={field => (
                                    <SlotWrapper
                                        {...field}
                                        label="Search Price Type"
                                    >
                                        <RadioGroup orientation="horizontal">
                                            <Radio
                                                value="fixed"
                                                label="Fixed"
                                                checked={field.field.value == 'fixed'}
                                                disabled={formDisabled}
                                            />
                                            <Radio
                                                value="range"
                                                label="Range"
                                                checked={field.field.value == 'range'}
                                                disabled={formDisabled}
                                            />
                                        </RadioGroup>
                                    </SlotWrapper>
                                )}
                            />
                            {searchPriceType == 'fixed' && (
                                <MobileFriendlyStack>
                                    <Controller
                                        disabled={formDisabled}
                                        name="hiddenPrice.amount"
                                        control={control}
                                        render={field => (
                                            <SlotInput
                                                label="Search Price"
                                                type="dollar"
                                                {...field}
                                                formControlProps={{
                                                    sx: { flex: 1 },
                                                }}
                                                startDecorator="$"
                                            />
                                        )}
                                    />
                                </MobileFriendlyStack>
                            )}
                            {searchPriceType == 'range' && (
                                <MobileFriendlyStack>
                                    <Controller
                                        disabled={formDisabled}
                                        name="hiddenPrice.min"
                                        control={control}
                                        render={field => (
                                            <SlotInput
                                                label="Min"
                                                type="dollar"
                                                {...field}
                                                formControlProps={{
                                                    sx: { flex: 1 },
                                                }}
                                                startDecorator="$"
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="hiddenPrice.max"
                                        disabled={formDisabled}
                                        control={control}
                                        render={field => (
                                            <SlotInput
                                                label="Max"
                                                type="dollar"
                                                {...field}
                                                formControlProps={{
                                                    sx: { flex: 1 },
                                                }}
                                                startDecorator="$"
                                            />
                                        )}
                                    />
                                </MobileFriendlyStack>
                            )}
                        </>
                    )}

                    <MobileFriendlyStack>
                        <Controller
                            name="authorityStartDate"
                            disabled={formDisabled}
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Authority Start Date"
                                    type="date"
                                    {...field}
                                    formControlProps={{
                                        sx: { flex: 1 },
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="authorityPeriod"
                            control={control}
                            disabled={formDisabled}
                            render={field => (
                                <SlotInput
                                    label="Authority Duration"
                                    type="number"
                                    slotProps={{
                                        input: {
                                            max: 90,
                                            min: 1,
                                        },
                                    }}
                                    {...field}
                                    formControlProps={{
                                        sx: { flex: 1 },
                                    }}
                                />
                            )}
                        />
                        <Input
                            label="Authority End Date"
                            type="date"
                            value={
                                authorityStartDate && authorityPeriod
                                    ? format(
                                          addDays(new Date(authorityStartDate), authorityPeriod - 1),
                                          'yyyy-MM-dd',
                                      )
                                    : undefined
                            }
                            readOnly
                            disabled
                            formControlProps={{
                                sx: { flex: 1 },
                            }}
                        />
                    </MobileFriendlyStack>
                    <Typography level="h4">Marketing Package</Typography>
                    <Stack
                        direction="row"
                        gap={4}
                        sx={{ alignItems: 'center' }}
                    >
                        {marketingPackage && (
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <Typography>{marketingPackage.packageName}</Typography>
                                <Typography>
                                    $
                                    {sum(marketingPackage.packageItems.map(prop('itemPrice'))).toLocaleString(
                                        'en-AU',
                                    )}
                                </Typography>
                            </Box>
                        )}
                        <Box>
                            {marketingPackage ? (
                                !formDisabled && (
                                    <IconButton
                                        size="sm"
                                        variant="outlined"
                                        onClick={() => setShowMarketingPackageModal(true)}
                                        disabled={
                                            formDisabled ||
                                            (listing.marketingPaymentStatus != undefined &&
                                                !(
                                                    listing.marketingPaymentStatus?.paymentStatus ==
                                                    'cancelled'
                                                ))
                                        }
                                    >
                                        <Edit />
                                    </IconButton>
                                )
                            ) : (
                                <Button
                                    variant="outlined"
                                    onClick={() => setShowMarketingPackageModal(true)}
                                    disabled={formDisabled}
                                >
                                    Add Marketing Package
                                </Button>
                            )}
                        </Box>
                    </Stack>
                    {marketingPackage && sum(marketingPackage.packageItems.map(prop('itemPrice'))) > 0 && (
                        <Stack
                            direction="row"
                            sx={{
                                gap: 2,
                                alignItems: 'flex-end',
                            }}
                        >
                            <Controller
                                name="marketingPaymentMethod"
                                control={control}
                                render={({ field }) => (
                                    <FormControl>
                                        <FormLabel>Payment Method</FormLabel>
                                        <Select
                                            {...field}
                                            onChange={(e, value) => field.onChange(value)}
                                            disabled={
                                                formDisabled ||
                                                (listing.marketingPaymentStatus != undefined &&
                                                    listing.marketingPaymentStatus?.paymentStatus !=
                                                        'cancelled')
                                            }
                                        >
                                            <Option value="rello">Rello</Option>
                                            <Option value="settlement">On Settlement</Option>
                                            <Option value="manual">Managed Externally</Option>
                                        </Select>
                                    </FormControl>
                                )}
                            />
                            {marketingPaymentMethod == 'rello' && (
                                <RelloMarketingPayment
                                    saveListing={async () => {
                                        if (formState.isDirty) {
                                            if (await trigger()) {
                                                //await handleSubmit(onSubmit)()
                                                await saveForm()
                                                return true
                                            } else {
                                                return false
                                            }
                                        }
                                        return true
                                    }}
                                />
                            )}
                            <CreateXeroMarketingInvoiceButton />
                        </Stack>
                    )}
                    <Typography
                        level="h4"
                        sx={{ mt: 1 }}
                    >
                        Additional Charges
                    </Typography>
                    <Stack gap={2}>
                        {additionalCharges.map((charge, i) => (
                            <AdditionalChargeItem
                                key={i}
                                charge={charge}
                                index={i}
                                removeAdditionalCharge={removeAdditionalCharge}
                                formDisabled={formDisabled}
                            />
                        ))}
                        {additionalCharges.length < 4 && !formDisabled && (
                            <Box>
                                <Button
                                    variant="outlined"
                                    onClick={() =>
                                        appendAdditionalCharge({
                                            description: '',
                                            amount: 0,
                                            payType: 'now',
                                            payable: format(new Date(), 'yyyy-MM-dd'),
                                        })
                                    }
                                >
                                    Add Charge
                                </Button>
                            </Box>
                        )}
                        <Controller
                            name="sensitiveInfo"
                            control={control}
                            disabled={formFullyDisabled}
                            render={field => (
                                <SlotWrapper
                                    label="Sensitive Info"
                                    {...field}
                                >
                                    <Textarea {...field.field} />
                                </SlotWrapper>
                            )}
                        />
                    </Stack>
                    {!formFullyDisabled && (
                        <Box sx={{ my: 2 }}>
                            <Button
                                startDecorator={<Save />}
                                loading={loading}
                                //type="submit"
                                onClick={saveForm}
                            >
                                Save
                            </Button>
                        </Box>
                    )}
                    {(import.meta.env.VITE_APP_STAGE == 'uat' || import.meta.env.DEV) && (
                        <>
                            <Box sx={{ mt: 4 }}>
                                <Button
                                    color="success"
                                    onClick={async () => {
                                        setPoolCertificate(undefined)
                                        //await getPropertyPoolInfo(listing.listingAddress!).then(setQbccInfo)
                                        await api.post(
                                            `/listing/${listingType}/${listing.listingId}/poolquery`,
                                        )
                                        /*await getPropertyPoolCertificate(listing.listingAddress!).then(data => {
                                    if (data) {
                                        setPoolCertificate(format(parseISO(data['Date Submitted']), 'dd/MM/yyyy'))
                                    } else {
                                        setPoolCertificate(false)
                                    }
                                })*/
                                    }}
                                >
                                    Get QBCC Pool Info
                                </Button>
                            </Box>
                            <Box>
                                <Button
                                    onClick={async () => {
                                        const { data } = (await api.post(
                                            `/listing/${listingType}/${listing.listingId}/settlementworksheet`,
                                        )) as { data: string }

                                        // Download PDF
                                        const blob = new Blob([data], { type: 'application/pdf' })
                                        saveAs(blob, 'Settlement Worksheet.pdf')
                                    }}
                                >
                                    Settlement Worksheet
                                </Button>
                            </Box>
                        </>
                    )}
                    {user.superUser && user.lastName == 'Bleier' && (
                        <Box>
                            <Button
                                color="danger"
                                loading={ocrLoading}
                                onClick={async () => {
                                    setOcrLoading(true)
                                    await api.post(
                                        `/listing/${listingType}/${listing.listingId}/agentappointmentform/azureocr`,
                                    )
                                    setOcrLoading(false)
                                }}
                            >
                                Azure OCR
                            </Button>
                        </Box>
                    )}
                    {!isNew && (
                        <Box sx={{ mt: 2 }}>
                            <FormStepper
                                title="Form 6"
                                formType="agentAppointmentForm"
                                createDisabled={
                                    vendors.length == 0 ||
                                    listing.agentUserId == undefined ||
                                    formFullyDisabled
                                }
                                validate={async () => {
                                    const start = Date.now()
                                    const requiredValues: Array<keyof UpdateSaleListingSchema> = [
                                        'authorityStartDate',
                                        'authorityPeriod',
                                        'lotNumber',
                                        'planNumber',
                                        //'titleReference',
                                        //'advertisedPrice',
                                        'hiddenPrice',
                                    ]

                                    var shouldFocus = true

                                    const block = await Promise.all(
                                        requiredValues.map(async reqVal => {
                                            const error = getValues(reqVal) == undefined

                                            if (error) {
                                                setError(
                                                    reqVal,
                                                    {
                                                        type: 'required',
                                                        message: 'Required for Form 6',
                                                    },
                                                    {
                                                        shouldFocus,
                                                    },
                                                )

                                                shouldFocus = false
                                                console.log('error', reqVal)
                                            }
                                            return error
                                        }),
                                    )
                                    if (block.some(x => x == true)) {
                                        console.log('block time', Date.now() - start)
                                        return false
                                    }
                                    return await trigger()
                                }}
                                preCreate={async () => {
                                    if (formState.isDirty) {
                                        return await saveForm()
                                        //return true
                                    } else {
                                        return true
                                    }
                                }}
                            />
                        </Box>
                    )}
                    <Box sx={{ mt: 2 }}>
                        <RenderListingValues
                            tab="listing"
                            disabled={formFullyDisabled}
                        />
                    </Box>
                </Stack>
                {/*<Box>
                    <Button
                        color="danger"
                        onClick={async () => {
                            await api.post(`/listing/${listing.listingId}/move/create`)
                        }}
                        sx={{ mt: 8 }}
                    >
                        SEND TO MOVE
                    </Button>
                </Box>*/}

                <MarketingPackageModal
                    open={showMarketingPackageModal}
                    close={() => setShowMarketingPackageModal(false)}
                    setValue={setValue}
                    currentPackage={marketingPackage}
                />
                <UnsavedModal
                    blocker={blocker}
                    save={(onSuccess, onFail) =>
                        /*handleSubmit(async x => {
                            await onSubmit(x)
                            onSuccess()
                        }, onFail)()*/

                        saveForm().then(x => (x ? onSuccess() : onFail()))
                    }
                />
            </form>

            <Modal
                open={maybeQbccInfo.isSome()}
                onClose={() => setQbccInfo(undefined)}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        p: 4,
                        m: 8,
                    }}
                >
                    {maybeQbccInfo.map(qbccInfo => (
                        <Stack spacing={2}>
                            <Typography sx={{ color: 'red' }}>
                                <b>Number of Pools:</b> {qbccInfo['Number of Pools']}
                            </Typography>
                            <Typography>
                                <b>Shared Pool Property:</b> {qbccInfo['Shared Pool Property']}
                            </Typography>
                            <Typography sx={{ color: 'red' }}>
                                <b>Site Name:</b> {qbccInfo['Site Name']}
                            </Typography>
                            <Typography>
                                <b>Street Name:</b> {qbccInfo['Street Name']}
                            </Typography>
                            <Typography>
                                <b>Street Number:</b> {qbccInfo['Street Number']}
                            </Typography>
                            <Typography>
                                <b>Street Type:</b> {qbccInfo['Street Type']}
                            </Typography>
                            <Typography>
                                <b>Suburb:</b> {qbccInfo['Suburb']}
                            </Typography>
                            <Typography>
                                <b>Unit Number:</b> {qbccInfo['Unit Number']}
                            </Typography>
                            <Typography sx={{ color: 'red' }}>
                                <b>Has Certificate:</b>{' '}
                                {maybePoolCertificate
                                    .map(cert => (cert == false ? 'No' : `Yes, submitted ${cert}`))
                                    .orSome('Loading...')}
                            </Typography>
                        </Stack>
                    ))}
                </Sheet>
            </Modal>
        </FormProvider>
    )
}

export default CoreListingDetails
