import { InternalAgentPayroll, InternalAgentStatement } from '@fastre/core/src/schemas/agentStatement'
import { InternalAutomationSchema } from '@fastre/core/src/schemas/automation'
import { InternalCalendarEventSchema } from '@fastre/core/src/schemas/calendar'
import { ChatGroupSchema, ChatSchema, FrontendChatGroupConfig } from '@fastre/core/src/schemas/chat'
import { InternalConjunctionalAgencySchema } from '@fastre/core/src/schemas/conjunionalAgency'
import { InternalEmailTemplateSchema } from '@fastre/core/src/schemas/emailTemplate'
import { InternalGroupSchema } from '@fastre/core/src/schemas/group'
import { InternalLedgerApprovalSchema, InternalLedgerSchema } from '@fastre/core/src/schemas/ledger'
import { InternalLedgerCommentsSchema } from '@fastre/core/src/schemas/ledgerComments'
import { InternalLinkSchema } from '@fastre/core/src/schemas/link'
import { ListingStatuses, ListingType } from '@fastre/core/src/schemas/listing'
import { InternalListingValueConfigSchema, ShowInTabOption } from '@fastre/core/src/schemas/listingConfig'
import {
    UpdateMarketingItemSchema,
    UpdateMarketingPackageSchema,
} from '@fastre/core/src/schemas/marketingPackage'
import { InternalNotificationSchema } from '@fastre/core/src/schemas/notification'
import { OrgSettingsSchema } from '@fastre/core/src/schemas/org'
import { InternalOrgIntegrationsSchema } from '@fastre/core/src/schemas/orgIntegrations'
import { InternalRoleSchema } from '@fastre/core/src/schemas/roles'
import { InternalSavedFiltersSchema } from '@fastre/core/src/schemas/savedFilter'
import { smsTemplateSchema } from '@fastre/core/src/schemas/smsTemplate'
import { InternalTodoSchema, SharedTodoSchema } from '@fastre/core/src/schemas/todo'
import { transformListingForTypesense, TypesenseListingSchema } from '@fastre/core/src/schemas/typesense'
import { FrontendUserSchema } from '@fastre/core/src/schemas/userMembership'
import { useApi } from 'api'
import { useWebSocket } from 'apiProviders/websocketProvider'
import { useUserData } from 'auth'
import { format } from 'date-fns'
import { useEffectNoInitial } from 'helperFunctions/react'
import { useOrgId } from 'helpers'
import { useListingContext } from 'listings/listingProvider'
import { Sort } from 'listings/listingsTable/sortableHeader'
import { Maybe } from 'monet'
import { assoc, dissoc, groupBy, map, pipe, prop, sortBy, uniqBy, without } from 'ramda'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import {
    ApiContext,
    ContextProvider,
    PaginatedApiContext,
    PaginatedContextProvider,
    TSApiContext,
    TSContextProvider,
    useObservable,
    useTSObservable,
} from './core'

const SaleListingContext = createContext<TSApiContext<TypesenseListingSchema>>(null as any)
const BdmListingContext = createContext<TSApiContext<TypesenseListingSchema>>(null as any)

/*export const useSaleListingsApi = (sub = true) => {
    console.log('useSaleListingsApi')

    return useTSObservable(SaleListingContext, sub)
}*/

export const useSaleListingsApi = (sub = false) => useObservable(SaleListingContext)
export const useListingsApi = useSaleListingsApi

export const useBdmListingsApi = (sub = true) => useTSObservable(BdmListingContext, sub)

export const useListingType = () => useParams<{ listingType: ListingType }>().listingType!
/*export const useListingsApi = (sub = true) => {
    //const type = useListingType()
    const sale = useSaleListingsApi(sub)
    //const bdm = useBdmListingsApi(sub)

    //return type == 'sale' ? sale : bdm
    return sale
}*/

export const ListingsProvider =
    type =>
    ({ children }) => {
        const api = useApi()
        const usersApi = useUsersApi()
        const Context = SaleListingContext

        return (
            <TSContextProvider
                Context={Context}
                getter={(params, signal) =>
                    api.post(`listings/${type}/search`, { ...params, signal }).then(prop('data'))
                }
                singleGetter={async id => {
                    console.log('lisitng single getter', id)
                    const [users, listing] = await Promise.all([
                        usersApi.promiseData,
                        api.get(`/listing/${type}/${id}`).then(prop('data')),
                    ])

                    return transformListingForTypesense(users)(listing) as TypesenseListingSchema
                }}
                children={children}
                defaultParams={{
                    status: without(['sold', 'withdrawn', 'not gained'], ListingStatuses),
                }}
            />
        )
    }

export const SaleListingsProvider = ListingsProvider('sale')
export const BdmListingsProvider = ListingsProvider('bdm')

const UsersContext = createContext<ApiContext<FrontendUserSchema[]>>(null as any)
export const useUsersApi = () => useObservable(UsersContext)

export const UsersProvider = ({ children }) => {
    const api = useApi()

    return (
        <ContextProvider
            Context={UsersContext}
            getter={(params, signal) =>
                api
                    .get('/users', { ...params, signal })
                    .then(prop('data'))
                    .then(sortBy(x => x.firstName))
            }
            children={children}
        />
    )
}

export const useFindUserFromId = () => {
    const users = useUsersApi()
    return (userId: string) =>
        users.maybeData.bind(users => Maybe.fromUndefined(users.find(user => user.userId == userId)))
}

const GroupsContext = createContext<ApiContext<InternalGroupSchema[]>>(null as any)
export const useGroupsApi = () => useObservable(GroupsContext)

export const GroupsProvider = ({ children }) => {
    const api = useApi()

    return (
        <ContextProvider
            Context={GroupsContext}
            getter={(params, signal) => api.get('/groups', { params, signal }).then(prop('data'))}
            children={children}
        />
    )
}

const LinksContext = createContext<ApiContext<{ category: string; links: InternalLinkSchema[] }[]>>(
    null as any,
)
export const useLinksApi = () => useObservable(LinksContext)

export const LinksApiProvider = ({ children }) => {
    const api = useApi()

    return (
        <ContextProvider
            Context={LinksContext}
            getter={async (params, signal) => {
                const [links, categoryOrder]: [InternalLinkSchema[], string[]] = await Promise.all([
                    api.get('/links', { params, signal }).then(prop('data')),
                    api.get('/linkcategoryorder', { params, signal }).then(prop('data')),
                ])

                return sortBy(
                    ([category, _]) => {
                        const order = categoryOrder.indexOf(category)
                        return order == -1 ? 999 : order
                    },
                    Object.entries(groupBy(prop('category'), links)),
                ).map(([category, links]) => ({
                    category,
                    links: sortBy(prop('order'), links),
                }))
            }}
            children={children}
        />
    )
}

interface MarketingItemsApiData extends ApiContext<UpdateMarketingItemSchema[]> {}

const MarketingItemsContext = createContext<MarketingItemsApiData>(null as any)
export const useMarketingItemsApi = () => useObservable(MarketingItemsContext)

export const MarketingItemsProvider = ({ children }) => {
    const api = useApi()

    return (
        <ContextProvider
            Context={MarketingItemsContext}
            getter={(params, signal) => api.get('/marketingitems', { params, signal }).then(prop('data'))}
            children={children}
        />
    )
}

interface MarketingPackagesApiData extends ApiContext<UpdateMarketingPackageSchema[]> {}

const MarketingPackagesContext = createContext<MarketingPackagesApiData>(null as any)
export const useMarketingPackagesApi = () => useObservable(MarketingPackagesContext)

export const MarketingPackagesProvider = ({ children }) => {
    const api = useApi()

    return (
        <ContextProvider
            Context={MarketingPackagesContext}
            getter={(params, signal) => api.get('/marketingpackages', { params, signal }).then(prop('data'))}
            children={children}
        />
    )
}

interface SmsTemplatesApiData extends ApiContext<smsTemplateSchema[]> {}

const SmsTemplatesContext = createContext<SmsTemplatesApiData>(null as any)
export const useSmsTemplatesApi = () => useObservable(SmsTemplatesContext)

export const SmsTemplatesProvider = ({ children }) => {
    const api = useApi()

    return (
        <ContextProvider
            Context={SmsTemplatesContext}
            getter={(params, signal) => api.get('/smstemplates', { params, signal }).then(prop('data'))}
            children={children}
        />
    )
}

interface ConjunctionalAgenciesApiData extends ApiContext<InternalConjunctionalAgencySchema[]> {}

const ConjunctionalAgenciesApiDataContext = createContext<ConjunctionalAgenciesApiData>(
    null as any as ConjunctionalAgenciesApiData,
)
export const useConjunctionalAgenciesApi = () => useObservable(ConjunctionalAgenciesApiDataContext)

export const ConjunctionalAgenciesApiDataProvider = ({ children }) => {
    const api = useApi()

    return (
        <ContextProvider
            Context={ConjunctionalAgenciesApiDataContext}
            getter={(params, signal) =>
                api.get('/conjunctionalagencies', { params, signal }).then(prop('data'))
            }
            children={children}
        />
    )
}

const ListingConfigContext = createContext<ApiContext<InternalListingValueConfigSchema[]>>(null as any)
export const useAllListingConfigApi = () => useObservable(ListingConfigContext)
export const useListingConfigApi = (filter?: ShowInTabOption) => {
    const { user } = useUserData()
    const core = useObservable(ListingConfigContext)

    const maybeData = useMemo(
        () =>
            core.maybeData.map(data =>
                (filter ? data.filter(x => x.showInTab == filter) : data).filter(
                    x => user.superUser || (x.visibleTo?.includes(user.roleId) ?? true),
                ),
            ),
        [core.maybeData, filter, user.roleId],
    )

    return {
        ...core,
        maybeData,
        data: maybeData.orUndefined(),
    }
}

export const ListingConfigProvider = ({ children }) => {
    const api = useApi()

    return (
        <ContextProvider
            Context={ListingConfigContext}
            getter={(params, signal) =>
                api
                    .get('/listingvalueconfig', { params, signal })
                    .then(prop('data'))
                    .then(sortBy(prop('configOrder')))
            }
            children={children}
        />
    )
}

const SavedFiltersContext = createContext<ApiContext<InternalSavedFiltersSchema>>(null as any)
export const useSavedFiltersApi = () => useObservable(SavedFiltersContext)
export const SavedFiltersProvider = ({ children }) => {
    const api = useApi()

    return (
        <ContextProvider
            Context={SavedFiltersContext}
            getter={(params, signal) => api.get('/savedfilters', { params, signal }).then(prop('data'))}
            children={children}
        />
    )
}

const AutomationContext = createContext<ApiContext<InternalAutomationSchema[]>>(null as any)
export const useAutomationsApi = () => useObservable(AutomationContext)
export const AutomationsProvider = ({ children }) => {
    const api = useApi()

    return (
        <ContextProvider
            Context={AutomationContext}
            getter={(params, signal) => api.get('/automation', { params, signal }).then(prop('data'))}
            children={children}
        />
    )
}

const NotificationsContext = createContext<PaginatedApiContext<InternalNotificationSchema>>(null as any)
const useCoreNotificationsApi = () => useObservable(NotificationsContext)
export const NotificationsProvider = ({ children }) => {
    const api = useApi()

    return (
        <PaginatedContextProvider
            Context={NotificationsContext}
            getter={(params, signal) => api.get('/notifications', { params, signal }).then(prop('data'))}
            children={children}
        />
    )
}

export const useNotificationsApi = () => {
    const coreNotificationsApi = useCoreNotificationsApi()
    const api = useApi()
    const wsNotification = useWebSocket('notification') as any

    const [notifications, setNotifications] = useState<InternalNotificationSchema[]>([])

    useEffect(() => {
        setNotifications(coreNotificationsApi.maybeData.orSome([]))
    }, [coreNotificationsApi.maybeData.orSome([]).map(prop('notificationId')).join('')])

    useEffect(() => {
        if (wsNotification) {
            setNotifications(notifications => [wsNotification, ...notifications])
        }
    }, [wsNotification])

    const setAllSeen = () => {
        setNotifications(notifications.map(assoc('seen', true)))
        return Promise.all(
            notifications
                .filter(x => !x.seen)
                .map(notification =>
                    api.post(`/notification/${notification.notificationId}/set`, {
                        seen: true,
                    }),
                ),
        )
    }

    return {
        ...coreNotificationsApi,
        maybeData: Maybe.some(notifications),
        setAllSeen,
    }
}

const ChatContext = createContext<TSApiContext<ChatSchema>>(null as any)
export const useChatApi = (chatGroupId: string) => {
    const core = useTSObservable(ChatContext)

    useEffect(() => {
        core.setParams({ chatGroupId })
    }, [chatGroupId])

    return core
}

export const ChatProvider = ({ children }) => {
    const api = useApi()
    return (
        <TSContextProvider
            Context={ChatContext}
            getter={(params, signal) => api.post(`/chat/search`, { ...params, signal }).then(prop('data'))}
            singleGetter={id => api.get(`/chat/${id}`).then(prop('data'))}
            children={children}
            sort="messageTime"
            pageSize={25}
            websocketHandler={{
                type: 'chat',
                handler: (data: any, setter, params) => {
                    if (data.chatGroupId == params.chatGroupId) {
                        setter(maybeData =>
                            maybeData.map(chats => [...chats.filter(x => x.id != data.id), data]),
                        )
                    }
                },
            }}
        />
    )
}

export const ChatGroupContext = createContext<ApiContext<ChatGroupSchema[]>>(null as any)
export const useChatGroupsApi = () => useObservable(ChatGroupContext)

export const ChatGroupProvider = ({ children }) => {
    const api = useApi()

    return (
        <ContextProvider
            Context={ChatGroupContext}
            getter={(params, signal) =>
                api.post('/chatgroups/list', { ...params, signal }).then(prop('data'))
            }
            children={children}
            websocketHandler={{
                type: 'chatGroup',
                handler: (data, setter) => {
                    console.log('chat group WS', data)
                    setter(maybeData => maybeData.map(groups => uniqBy(prop('id'), [...groups, data])))
                },
            }}
        />
    )
}

export const ChatConfigContext = createContext<ApiContext<FrontendChatGroupConfig>>(null as any)
export const useChatConfigApi = () => useObservable(ChatConfigContext)

export const ChatConfigProvider = ({ children }) => {
    const api = useApi()

    return (
        <ContextProvider
            Context={ChatConfigContext}
            getter={(params, signal) => api.get('/chatconfig', { signal }).then(prop('data'))}
            children={children}
            websocketHandler={{
                type: 'chatConfig',
                handler: (data, setter) => {
                    console.log('chat config WS', data)
                    setter(Maybe.some(data))
                },
            }}
        />
    )
}

export const TodoContext = createContext<ApiContext<InternalTodoSchema>>(null as any)
export const useTodoApi = (sub = true) => useObservable(TodoContext, sub)
export const TodoProvider = ({ children }) => {
    const api = useApi()

    return (
        <ContextProvider
            Context={TodoContext}
            getter={(params, signal) => api.get('/todo', { signal }).then(prop('data'))}
            children={children}
        />
    )
}

export const OrgDetailsContext = createContext<ApiContext<OrgSettingsSchema>>(null as any)
export const useOrgDetailsApi = () => useObservable(OrgDetailsContext)
export const OrgDetailsProvider = ({ children }) => {
    const api = useApi()

    return (
        <ContextProvider
            Context={OrgDetailsContext}
            getter={(params, signal) => api.get('/orgdetails', { signal }).then(prop('data'))}
            children={children}
        />
    )
}

export const SharedTodosContext = createContext<ApiContext<SharedTodoSchema[]>>(null as any)
export const useSharedTodosApi = (showAll = false) => {
    const { user } = useUserData()
    const core = useObservable(SharedTodosContext)

    const maybeData = useMemo(
        () =>
            core.maybeData.map(data => (showAll ? data : data.filter(x => x.userIds.includes(user.userId)))),
        [core.maybeData, showAll],
    )

    return {
        ...core,
        maybeData,
        data: maybeData.orUndefined(),
    }
}
export const SharedTodosProvider = ({ children }) => {
    const api = useApi()

    return (
        <ContextProvider
            Context={SharedTodosContext}
            getter={(params, signal) => api.post('/sharedtodo/list', { signal }).then(prop('data'))}
            children={children}
        />
    )
}

export interface ListingFilesApiData {
    /*id: string;*/
    isFolder: boolean
    isEmail: boolean
    Key: string
    name: string
    Size: number
    loading?: boolean
}

export const ListingFilesContext = createContext<ApiContext<ListingFilesApiData[]>>(null as any)
export const useListingFilesApi = (
    { excludeFolders }: { excludeFolders?: boolean } = { excludeFolders: false },
) => {
    const core = useObservable(ListingFilesContext)

    const maybeData = useMemo(
        () => core.maybeData.map(data => (excludeFolders ? data.filter(x => !x.isFolder) : data)),
        [core.maybeData, excludeFolders],
    )

    return {
        ...core,
        maybeData,
        data: maybeData.orUndefined(),
    }
}
export const ListingFilesProvider = ({ children }) => {
    const api = useApi()
    const orgId = useOrgId()
    const { listing } = useListingContext()
    const listingType = useListingType()

    const locationRoot = `${orgId}/${listingType}/${listing.listingId}/files/`

    return (
        <ContextProvider
            Context={ListingFilesContext}
            getter={(params, signal) =>
                api.get(`/listing/${listingType}/${listing.listingId}/files`, { signal }).then(prop('data'))
            }
            transformer={pipe(
                map(({ Key, Size, loading }) => {
                    const isFolder = Key.endsWith('/')
                    const newKey = Key.replace(locationRoot, '')

                    return {
                        isFolder,
                        isEmail: Key.endsWith('.eml') || Key.endsWith('.msg'),
                        Key: newKey,
                        name: newKey.replace(/\/$/, ''), // Remove trailing '/'
                        Size,
                        loading,
                    }
                }) as any,
                sortBy((x: any) => x.name),
                sortBy((x: any) => (x.isFolder ? 0 : 1)),
            )}
            children={children}
        />
    )
}

const calendarEventContext = createContext<ApiContext<InternalCalendarEventSchema[]>>(null as any)
export const useCalendarEventsApi = () => useObservable(calendarEventContext)
export const CalendarEventsProvider = ({ children }) => {
    const api = useApi()

    return (
        <ContextProvider
            Context={calendarEventContext}
            getter={(params, signal) =>
                api
                    .post(
                        '/calendar/search',
                        {
                            //start: Date.parse(info.startStr),
                            //end: Date.parse(info.endStr),
                            ...params,
                        },
                        signal,
                    )
                    .then(prop('data'))
            }
            children={children}
        />
    )
}

const orgIntegrationsContext = createContext<ApiContext<InternalOrgIntegrationsSchema>>(null as any)
export const useOrgIntegrationsApi = () => useObservable(orgIntegrationsContext)
export const OrgIntegrationsProvider = ({ children }) => {
    const api = useApi()

    return (
        <ContextProvider
            Context={orgIntegrationsContext}
            getter={(params, signal) => api.get('/integrations', { signal }).then(prop('data'))}
            children={children}
        />
    )
}

const rolesContext = createContext<ApiContext<InternalRoleSchema[]>>(null as any)
export const useRolesApi = () => useObservable(rolesContext)
export const RolesProvider = ({ children }) => {
    const api = useApi()

    return (
        <ContextProvider
            Context={rolesContext}
            getter={(params, signal) => api.get('/roles', { signal }).then(prop('data'))}
            children={children}
        />
    )
}

const LedgerMonthContext = createContext<{
    month: string
    setMonth: React.Dispatch<React.SetStateAction<string>>
    sort: Sort
    setSort: React.Dispatch<React.SetStateAction<Sort>>
}>(null as any)
export const useLedgerMonth = () => useContext(LedgerMonthContext)
export const LedgerMonthProvider = ({ children }) => {
    const [month, setMonth] = useState(
        localStorage.getItem('ledgerMonth') ?? format(new Date(), 'yyyy-MM-01'),
    )
    const [sort, setSort] = useState<Sort>({
        sort: 'date',
        sortDirection: 'desc',
    })

    useEffectNoInitial(() => localStorage.setItem('ledgerMonth', month), [month])

    return (
        <LedgerMonthContext.Provider value={{ month, setMonth, sort, setSort }}>
            {children}
        </LedgerMonthContext.Provider>
    )
}

const bulkLegerContext = createContext<
    ApiContext<{
        items: InternalLedgerSchema[]
        approvals: InternalLedgerApprovalSchema[]
        open: boolean
    }>
>(null as any)
export const useBulkLedgerApi = (sub = true) => {
    const core = useObservable(bulkLegerContext, sub)
    const { month } = useLedgerMonth()

    useEffect(() => {
        core.setParams({ month })
    }, [month])

    return dissoc('setParams', core)
}
export const BulkLedgerProvider = ({ children }) => {
    const api = useApi()
    const { month } = useLedgerMonth()

    return (
        <ContextProvider
            Context={bulkLegerContext}
            getter={(props, signal) => api.post('/ledger/bulklist', props, signal).then(prop('data'))}
            defaultParams={{
                month,
            }}
            children={children}
        />
    )
}

export type LedgerApiContext = ApiContext<{
    items: InternalLedgerSchema[]
    ledgerApproval: InternalLedgerApprovalSchema
    ledgerOpen?: boolean
    ledgerComments: InternalLedgerCommentsSchema[]
}>

const UserLedgerApi = createContext<LedgerApiContext>(null as any)
export const useUserLedgerApi = (userId: string) => {
    const core = useObservable(UserLedgerApi)

    const { month } = useLedgerMonth()

    useEffect(() => {
        core.setParams(assoc('month', month))
    }, [month])

    useEffect(() => {
        core.setParams(assoc('userId', userId))
    }, [userId])

    return core
}
export const UserLedgerProvider = ({ children }) => {
    const api = useApi()

    return (
        <ContextProvider
            Context={UserLedgerApi}
            getter={(params, signal) =>
                params.userId
                    ? api
                          .post(
                              '/ledger/list',
                              {
                                  filter: 'user',
                                  userId: params.userId,
                                  month: params.month,
                              },
                              signal,
                          )
                          .then(prop('data'))
                    : Promise.resolve({ items: [], approved: false })
            }
            children={children}
        />
    )
}

const ListingLedgerApi = createContext<LedgerApiContext>(null as any)
export const useListingLedgerApi = (listingId: string) => {
    const core = useObservable(ListingLedgerApi)

    useEffect(() => {
        core.setParams({ listingId })
    }, [listingId])

    return core
}
export const ListingLedgerProvider = ({ children }) => {
    const api = useApi()
    const { listing } = useListingContext()

    return (
        <ContextProvider
            Context={ListingLedgerApi}
            getter={(params, signal) =>
                listing.listingId
                    ? api
                          .post(
                              '/ledger/list',
                              {
                                  filter: 'listing',
                                  listingId: listing.listingId,
                                  userId: listing.agentUserId,
                              },
                              signal,
                          )
                          .then(prop('data'))
                    : Promise.resolve({ items: [], approved: false })
            }
            children={children}
        />
    )
}

export const AgentStatemementContext = createContext<ApiContext<InternalAgentStatement[]>>(null as any)
export const useAgentStatementApi = (userId: string, sub = true) => {
    const core = useObservable(AgentStatemementContext, sub)

    useEffect(() => {
        core.setParams({ userId })
    }, [userId])

    return core
}
export const AgentStatementProvider = ({ children }) => {
    const api = useApi()

    return (
        <ContextProvider
            Context={AgentStatemementContext}
            getter={(params, signal) => api.post('/getagentstatement', params, signal).then(prop('data'))}
            children={children}
        />
    )
}

const PayrollContext = createContext<ApiContext<InternalAgentPayroll[]>>(null as any)
export const usePayrollApi = (userId: string, sub = true) => {
    const core = useObservable(PayrollContext, sub)

    useEffect(() => {
        core.setParams({ userId })
    }, [userId])

    return core
}
export const PayrollProvider = ({ children }) => {
    const api = useApi()

    return (
        <ContextProvider
            Context={PayrollContext}
            getter={(params, signal) => api.post('/getpayrolls', params, signal).then(prop('data'))}
            children={children}
        />
    )
}

export interface XeroAccount {
    AccountID: string
    Name: string
    Description: string
    AddToWatchlist: boolean
    BankAccountType: string
    Class: string
    Code: string
    EnablePaymentsToAccount: boolean
    HasAttachments: boolean
    ReportingCode: string
    ReportingCodeName: string
    ShowInExpenseClaims: boolean
    Status: string
    SystemAccount: string
    TaxType: string
    Type: string
    UpdatedDateUTC: string
}

export const XeroAccountsContext = createContext<ApiContext<XeroAccount[]>>(null as any)
export const useXeroAccountsApi = () => useObservable(XeroAccountsContext)
export const XeroAccountsProvider = ({ children }) => {
    const api = useApi()
    return (
        <ContextProvider
            Context={XeroAccountsContext}
            getter={(params, signal) => api.post('/xero/getaccounts', { signal }).then(prop('data'))}
            children={children}
        />
    )
}

export const EmailTemplatesContext = createContext<ApiContext<InternalEmailTemplateSchema[]>>(null as any)
export const useEmailTemplatesApi = () => useObservable(EmailTemplatesContext)
export const EmailTemplatesProvider = ({ children }) => {
    const api = useApi()

    return (
        <ContextProvider
            Context={EmailTemplatesContext}
            getter={(params, signal) => api.get('/emailtemplates', { signal }).then(prop('data'))}
            children={children}
        />
    )
}
