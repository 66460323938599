import { Tab, TabList, Tabs } from '@mui/joy'
import Box from '@mui/joy/Box'
import { useUsersApi } from 'apiProviders'
import { useUserData } from 'auth'
import Loading from 'components/Loading'
import SectionHead from 'components/sectionHead'
import { useSearchParamState } from 'helperFunctions/react'
import { useParams } from 'react-router'
import MonthlyCharges from '../monthlyCharges'
import AgentStatement from './agentStatement'
import CoreEditUser from './coreEditUser'
import LedgerAuditLog from './ledgerAuditLog'
import PayrollTab from './payrollTab'
import UserLedger from './userLedger'

export default function editUser({ breadcrumbs }: { breadcrumbs?: string[] }) {
    const usersApi = useUsersApi()
    const { user: userData } = useUserData()
    const { userId } = useParams<{ userId: string }>()
    const [activeTab, setActiveTab] = useSearchParamState<string>('tab', 'Details')

    if (!usersApi.data) {
        return <Loading />
    }

    const user = usersApi.data.find(user => user.userId == userId)!

    return (
        <>
            <SectionHead
                title={user ? `${user.firstName} ${user.lastName}` : 'New User'}
                breadcrumbs={breadcrumbs ?? ['Users']}
            />
            <Tabs
                value={activeTab}
                onChange={(e, val) => setActiveTab(val as any)}
                sx={{ mx: 0 }}
            >
                {user != undefined && (
                    <TabList>
                        <Tab value="Details">Details</Tab>
                        {userData.permissions.includes('monthlyCharges.view') && (
                            <Tab value="Monthly Charges">Monthly Charges</Tab>
                        )}
                        <Tab value="Ledger">Ledger</Tab>
                        {userData.superUser && <Tab value="Ledger Audit Log">Ledger Audit Log</Tab>}
                        {user.conjunctionalAgencyId == undefined && (
                            <>
                                <Tab value="Agent Statement">Agent Statement</Tab>
                                <Tab value="Payroll">Payroll</Tab>
                            </>
                        )}
                    </TabList>
                )}
                <Box sx={{ py: 2 }}>
                    <Box
                        sx={{
                            display: activeTab == 'Details' ? undefined : 'none',
                        }}
                    >
                        <CoreEditUser user={user} />
                    </Box>
                    <Box
                        sx={{
                            display: activeTab == 'Monthly Charges' ? undefined : 'none',
                        }}
                    >
                        <MonthlyCharges user={user} />
                    </Box>
                    <Box
                        sx={{
                            display: activeTab == 'Ledger' ? undefined : 'none',
                        }}
                    >
                        <UserLedger />
                    </Box>
                    {activeTab == 'Ledger Audit Log' && <LedgerAuditLog />}
                    {user.conjunctionalAgencyId == undefined &&
                        userData.permissions.includes('agentStatement.view') && (
                            <>
                                <Box sx={{ display: activeTab == 'Payroll' ? undefined : 'none' }}>
                                    <PayrollTab />
                                </Box>
                                <Box
                                    sx={{
                                        display: activeTab == 'Agent Statement' ? undefined : 'none',
                                    }}
                                >
                                    <AgentStatement />
                                </Box>
                            </>
                        )}
                </Box>
            </Tabs>
        </>
    )
}
