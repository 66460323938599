import { EmailTemplateSchema } from '@fastre/core/src/schemas/emailTemplate'
import { Box, Button, DialogActions, DialogContent, DialogTitle, Modal, ModalDialog, Stack } from '@mui/joy'
import { useApi } from 'api'
import { useEmailTemplatesApi } from 'apiProviders'
import ButtonSheet from 'components/buttonSheet'
import Input from 'components/input'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { prop } from 'ramda'
import { useEffect, useRef, useState } from 'react'
import EmailEditor, { EditorRef } from 'react-email-editor'
import { v4 as uuid } from 'uuid'

interface Props {
    onClose: () => void
    template?: EmailTemplateSchema
}

const EditTemplateModal = ({ template, onClose }: Props) => {
    const emailTemplatesApi = useEmailTemplatesApi()
    const api = useApi()
    const emailEditorRef = useRef<EditorRef>(null)

    const [templateName, setTemplateName] = useState('')
    const [editorLoaded, setEditorLoaded] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setTemplateName(template?.templateName ?? '')
    }, [template])

    useEffect(() => {
        if (template && editorLoaded) {
            emailEditorRef.current?.editor?.loadDesign(template.design)
        }
    }, [template, editorLoaded])

    const getEditorJson = () => {
        return new Promise(resolve => {
            emailEditorRef.current!.editor!.exportHtml(data => {
                resolve(data.design)
            })
        })
    }

    return (
        <ModalDialog sx={{ width: '100%', height: '100%' }}>
            <DialogTitle>Email Template</DialogTitle>
            <DialogContent>
                <Input
                    label="Template Name"
                    placeholder="Template Name"
                    value={templateName}
                    onChange={setTemplateName}
                />
                <EmailEditor
                    ref={emailEditorRef}
                    onReady={() => setEditorLoaded(true)}
                    //onReady={onReady}
                    options={{
                        mergeTags: {
                            receiver_name: {
                                name: 'Receiver',
                                mergeTags: {
                                    receiverName: {
                                        name: 'First Name',
                                        value: '{{receiverFirstName}}',
                                        sample: 'John',
                                    },
                                    receiverLastName: {
                                        name: 'Last Name',
                                        value: '{{receiverLastName}}',
                                        sample: 'Doe',
                                    },
                                },
                            },
                            agent: {
                                name: 'Agent',
                                mergeTags: {
                                    agent_first_name: {
                                        name: 'First Name',
                                        value: '{{agentFirstName}}',
                                        sample: 'Jane',
                                    },
                                    agent_last_name: {
                                        name: 'Last Name',
                                        value: '{{agentLastName}}',
                                        sample: 'Smith',
                                    },
                                },
                            },
                            listing_address: {
                                name: 'Listing Address',
                                mergeTags: {
                                    listing_street_address: {
                                        name: 'Address',
                                        value: '{{listingAddress}}',
                                        sample: '9 Hamilton Ave',
                                    },
                                    listing_city: {
                                        name: 'City',
                                        value: '{{listingCity}}',
                                        sample: 'Surfers Paradise',
                                    },
                                    listing_state: {
                                        name: 'State',
                                        value: '{{listingState}}',
                                        sample: 'QLD',
                                    },
                                    listing_zip: {
                                        name: 'Zip',
                                        value: '{{listingZip}}',
                                        sample: '4217',
                                    },
                                },
                            },
                        },
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    loading={loading}
                    onClick={async () => {
                        setLoading(true)
                        const updatedEmailTemplates = await api
                            .post('/emailtemplates', {
                                templateId: template?.templateId ?? uuid(),
                                templateName,
                                design: await getEditorJson(),
                            })
                            .then(prop('data'))
                        await emailTemplatesApi.setVals(updatedEmailTemplates)
                        setLoading(false)
                        onClose()
                    }}
                >
                    Save
                </Button>
                <Button
                    variant="outlined"
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="outlined"
                    color="danger"
                    sx={{ marginRight: 'auto' }}
                    loading={loading}
                    onClick={async () => {
                        setLoading(true)
                        const updatedEmailTemplates = await api
                            .post(`/emailtemplates/delete`, {
                                templateId: template?.templateId,
                            })
                            .then(prop('data'))
                        await emailTemplatesApi.setVals(updatedEmailTemplates)
                        setLoading(false)
                        onClose()
                    }}
                >
                    Delete
                </Button>
            </DialogActions>
        </ModalDialog>
    )
}

export default () => {
    const emailTemplatesApi = useEmailTemplatesApi()

    const [editTemplate, setEditTemplate] = useState<EmailTemplateSchema>()

    return (
        <>
            <Stack
                direction="column"
                spacing={2}
                sx={{ maxWidth: '400px' }}
            >
                {emailTemplatesApi.data?.map(template => (
                    <ButtonSheet
                        key={template.templateId}
                        onClick={() => setEditTemplate(template)}
                    >
                        {template.templateName}
                    </ButtonSheet>
                ))}
                <Box sx={{ mt: 8 }}>
                    <Button
                        variant="soft"
                        onClick={() => setEditTemplate({ templateId: uuid(), templateName: '', design: {} })}
                    >
                        Add Template
                    </Button>
                </Box>
            </Stack>
            <Modal
                open={editTemplate != undefined}
                onClose={dontCloseOnBackgroundClick(() => setEditTemplate(undefined))}
            >
                <EditTemplateModal
                    template={editTemplate}
                    onClose={() => setEditTemplate(undefined)}
                />
            </Modal>
        </>
    )
}
