import z from 'zod'
import { AddressSchema, mobileOrPhoneType } from './generic'

export const VendorTypes = ['individual', 'company'] as const
export type VendorType = (typeof VendorTypes)[number]

const CoreVendorSchema = z.object({
    firstName: z.string(),
    lastName: z.string(),
    mobileNumber: mobileOrPhoneType.optional(),
    email: z.string().email(),
    address: AddressSchema.optional(),
})

const IndividualVendorSchema = z.object({
    vendorType: z.literal('individual'),
})

const CompanyVendorSchema = z.object({
    vendorType: z.literal('company'),
    companyName: z.string(),
    abn: z.string().optional(),
    acn: z.string().optional(),
})
/*.refine(data => data.abn || data.acn, {
        message: 'Either abn or acn must be provided',
        path: ['abn', 'acn'],
    })*/

export const VendorSchema = CoreVendorSchema.and(z.union([IndividualVendorSchema, CompanyVendorSchema]))

export type VendorSchema = z.infer<typeof VendorSchema> & {}

export const getVendorName = (vendor?: VendorSchema) => {
    if (vendor == undefined) return ''

    if (vendor.vendorType === 'company') {
        return `${vendor.companyName} - ${vendor.firstName} ${vendor.lastName}`
    } else {
        return `${vendor.firstName} ${vendor.lastName}`
    }
}
