/* eslint-disable jsx-a11y/anchor-is-valid */
import { hasValue } from '@fastre/core/src/helperFunctions/array'
import { capitalize } from '@fastre/core/src/helperFunctions/string'
import { getColumnName, SingleColumnCongifSchema } from '@fastre/core/src/schemas/columnConfig'
import { formatAddress } from '@fastre/core/src/schemas/generic'
import { SaleListingSearchSchema, TypesenseListingSchema } from '@fastre/core/src/schemas/typesense'
import { DoneAllRounded, EmailRounded, UploadFileRounded } from '@mui/icons-material'
import { CircularProgress, Dropdown, Menu, MenuButton, MenuItem, Typography } from '@mui/joy'
import Chip from '@mui/joy/Chip'
import Sheet from '@mui/joy/Sheet'
import Table from '@mui/joy/Table'
import { useApi } from 'api'
import { useFindUserFromId, useListingConfigApi, useListingsApi, useListingType } from 'apiProviders'
import { useUserData } from 'auth'
import { addDays, differenceInDays, format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { listingStatusToColor, useListingStatusOptions } from '../helpers'
import SortableHeader, { Sort } from './sortableHeader'

const AuthorityEndRender = ({ listing }: { listing: TypesenseListingSchema }) => {
    const { listingStatus } = listing
    if (listing.authorityStartDate == undefined || listing.authorityPeriod == undefined) {
        return ''
    }

    const authorityEnd = addDays(new Date(listing.authorityStartDate), listing.authorityPeriod - 1)
    const daysToExpiry = differenceInDays(authorityEnd, new Date())

    return (
        <Typography
            component="span"
            color={
                daysToExpiry && (listingStatus == 'available' || listingStatus == 'offline')
                    ? daysToExpiry < 1
                        ? 'danger'
                        : daysToExpiry < 5
                          ? 'warning'
                          : undefined
                    : undefined
            }
        >
            {format(authorityEnd, 'dd/MM/yyyy')}
        </Typography>
    )
}

const ActionChip = ({ listing, actionId }: { listing: TypesenseListingSchema; actionId: string }) => {
    const api = useApi()
    const listingsApi = useListingsApi()
    const listingConfigApi = useListingConfigApi()
    const listingType = useListingType()

    const [loading, setLoading] = useState(false)

    if (loading) {
        return <CircularProgress sx={{ '--CircularProgress-size': '20px' }} />
    }

    return listingConfigApi.maybeData
        .map(configs => {
            const config = configs.find(x => x.id == actionId)
            const configValue = listing.additionalDetails
                ? config?.configValues.find(v => v.value === listing.additionalDetails[actionId])
                : config?.configValues[0]

            return (
                <Dropdown>
                    <MenuButton
                        slots={{ root: Chip }}
                        slotProps={{
                            root: {
                                variant: 'soft',
                                size: 'sm',
                                color: configValue?.color,
                                /*sx: {
                                    backgroundColor: `${configValue?.color}.softActiveBg`,
                                },*/
                                onClick: e => e.stopPropagation(),
                            },
                        }}
                    >
                        {listing.additionalDetails?.[actionId] ??
                            listingConfigApi?.data?.find(x => x.id == actionId)?.configValues[0].value ??
                            'Loading...'}
                    </MenuButton>
                    <Menu onClick={e => e.stopPropagation()}>
                        {config?.configValues.map(value => (
                            <MenuItem
                                key={value.value}
                                onClick={async e => {
                                    e.stopPropagation()
                                    setLoading(true)
                                    await api.post(
                                        `/listing/${listingType}/${listing.id}/additionaldetails`,
                                        {
                                            additionalDetails: {
                                                [actionId]: value.value,
                                            },
                                        },
                                    )
                                    await listingsApi.refreshSpecific(listing.id)
                                    setLoading(false)
                                }}
                            >
                                {value.value}
                            </MenuItem>
                        ))}
                    </Menu>
                </Dropdown>
            )
        })
        .orSome(<>loading...</>)
}

const StatusChip = ({ listing }: { listing: TypesenseListingSchema }) => {
    const api = useApi()
    const listingsApi = useListingsApi()
    const listingType = useListingType()
    const listingStatusOptions = useListingStatusOptions(listing)

    const [loading, setLoading] = useState(false)

    if (loading) {
        return <CircularProgress sx={{ '--CircularProgress-size': '20px' }} />
    }

    return (
        <Dropdown>
            <MenuButton
                slots={{ root: Chip }}
                slotProps={{
                    root: {
                        variant: 'soft',
                        color: listingStatusToColor(listing.listingStatus),
                        onClick: e => e.stopPropagation(),
                    },
                }}
            >
                {listing.listingStatus.split(' ').map(capitalize).join(' ')}
            </MenuButton>
            <Menu onClick={e => e.stopPropagation()}>
                {listingStatusOptions.map(({ value, disabled }) => (
                    <MenuItem
                        key={value}
                        disabled={disabled}
                        onClick={async e => {
                            e.stopPropagation()
                            setLoading(true)
                            await api.post(`/listing/${listingType}/${listing.id}/status`, {
                                listingStatus: value,
                            })
                            await listingsApi.refreshSpecific(listing.id)
                            setLoading(false)
                        }}
                    >
                        {capitalize(value)}
                    </MenuItem>
                ))}
            </Menu>
        </Dropdown>
    )
}

const RenderCell = ({ col, row }: { col: SingleColumnCongifSchema; row: TypesenseListingSchema }) => {
    const findUserFromId = useFindUserFromId()

    switch (col.key) {
        case 'status':
            return <StatusChip listing={row} />
        case 'agent':
            return (
                row.agentUserId && (
                    <Chip
                        variant="soft"
                        //size="sm"
                    >
                        {findUserFromId(row.agentUserId)
                            .map(user => `${user.firstName} ${user.lastName}`)
                            .orSome('loading...')}
                    </Chip>
                )
            )
        case 'agentAppointmentForm':
            return (
                <>
                    {row.agentAppointmentForm?.formStatus == 'sent' && (
                        <EmailRounded fontSize={'lg' as any} />
                    )}
                    {row.agentAppointmentForm?.formStatus == 'completed' && (
                        <DoneAllRounded
                            color="success"
                            fontSize={'lg' as any}
                        />
                    )}
                    {row.agentAppointmentForm?.formStatus == 'manual upload' && (
                        <UploadFileRounded
                            color="success"
                            fontSize={'lg' as any}
                        />
                    )}
                </>
            )
        case 'contractForm':
            return (
                <>
                    {row.contractForm?.formStatus == 'sent' && <EmailRounded fontSize={'lg' as any} />}
                    {row.contractForm?.formStatus == 'completed' && (
                        <DoneAllRounded
                            color="success"
                            fontSize={'lg' as any}
                        />
                    )}
                    {row.contractForm?.formStatus == 'manual upload' && (
                        <UploadFileRounded
                            color="success"
                            fontSize={'lg' as any}
                        />
                    )}
                </>
            )
        case 'authorityEnd':
            return <AuthorityEndRender listing={row} />

        case 'settlementDate':
            const date = row.settlementDate
            return date ? format(date, 'dd/MM/yyyy') : ''

        case 'contractDate':
            const contractDate = row.contract?.contractDate
            return contractDate ? format(contractDate, 'dd/MM/yyyy') : ''

        case 'action':
            return (
                <ActionChip
                    listing={row}
                    actionId={col.actionId}
                />
            )
    }
}

export default function ListingsTable() {
    const navigate = useNavigate()
    const api = useApi()
    const listingsApi = useListingsApi()
    const listingConfigApi = useListingConfigApi()
    const { user, refreshUser } = useUserData()

    // Check for invalid actionIds in user config
    useEffect(() => {
        if (listingConfigApi.data) {
            const invalidAtionConfigIds = user.customColumnsConfig
                .map(x => (x.key == 'action' ? x.actionId : undefined))
                .filter(hasValue)
                .filter(x => !listingConfigApi.data!.map(x => x.id).includes(x))

            if (invalidAtionConfigIds.length > 0) {
                console.log('invalidAtionConfigIds', invalidAtionConfigIds)
                api.post(
                    '/columnconfig',
                    user.customColumnsConfig.filter(
                        x => !(x.key == 'action' && invalidAtionConfigIds.includes(x.actionId)),
                    ),
                ).then(refreshUser)
            }
        }
    }, [listingConfigApi.maybeData.isSome()])

    const rows = listingsApi.maybeData.orSome([])

    const [sort, setSort] = useState<Sort | undefined>({
        sort: listingsApi.params.sort,
        sortDirection: listingsApi.params.sortDirection,
    })

    useEffect(() => {
        const searchParams: Partial<SaleListingSearchSchema> = {
            sort: sort?.sort,
            sortDirection: sort?.sortDirection,
        }

        listingsApi.setParams(x => ({
            ...x,
            ...searchParams,
        }))
    }, [sort])

    //console.log('render table', listingConfigApi.data)

    return (
        <Sheet
            variant="outlined"
            sx={{
                display: { xs: 'none', sm: 'block' },
                width: '100%',
                borderRadius: 'sm',
                flexShrink: 1,
                overflow: 'auto',
                minHeight: 0,
                mb: 6,
            }}
        >
            <Table
                stickyHeader
                hoverRow
                sx={{
                    '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                    '--Table-headerUnderlineThickness': '1px',
                    '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                    //'--TableCell-paddingY': '4px',
                    //'--TableCell-paddingX': '8px',
                }}
            >
                <thead>
                    <tr>
                        <th>
                            <SortableHeader
                                sort={sort}
                                setSort={setSort}
                                sortString="addressString"
                            >
                                Listing
                            </SortableHeader>
                        </th>
                        {user.customColumnsConfig.map(col =>
                            col.key == 'action' ? (
                                <th key={col.actionId}>
                                    {listingConfigApi.data?.find(x => x.id == col.actionId)?.configName ??
                                        'loading...'}
                                </th>
                            ) : (
                                <th key={col.key}>
                                    {col.key == 'contractForm' || col.key == 'agentAppointmentForm' ? (
                                        getColumnName(col.key)
                                    ) : (
                                        <SortableHeader
                                            sort={sort}
                                            setSort={setSort}
                                            sortString={
                                                {
                                                    status: 'listingStatus',
                                                    agent: 'agentUserId',
                                                    authorityEnd: 'authorityEndDate',
                                                    contractDate: 'contract.contractDate',
                                                }[col.key] ?? col.key
                                            }
                                        >
                                            {getColumnName(col.key)}
                                        </SortableHeader>
                                    )}
                                </th>
                            ),
                        )}
                    </tr>
                </thead>
                <tbody>
                    {rows.map(row => (
                        <tr
                            key={row.listingId}
                            onClick={() => {
                                const goToContractTab = ['under contract', 'unconditional', 'sold'].includes(
                                    row.listingStatus,
                                )
                                navigate(`${row.listingId}/${goToContractTab ? 'contract' : 'listing'}`)
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <td>
                                {row.listingAddress ? formatAddress(row.listingAddress) : 'no street name'}
                            </td>
                            {user.customColumnsConfig.map(col => (
                                <td
                                    key={col.key == 'action' ? col.actionId : col.key}
                                    style={{ overflow: 'hidden' }}
                                >
                                    <RenderCell
                                        col={col}
                                        row={row}
                                    />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Sheet>
    )
}
