import { OrgSettingsSchema } from '@fastre/core/src/schemas/org'
import { zodResolver } from '@hookform/resolvers/zod'
import { Save } from '@mui/icons-material'
import { Box, Button, Checkbox, Divider, Stack, Textarea, Typography } from '@mui/joy'
import { useApi } from 'api'
import { useOrgDetailsApi } from 'apiProviders'
import axios from 'axios'
import Loading from 'components/Loading'
import { SlotInput, SlotWrapper } from 'components/input'
import { useShowSnack } from 'components/snackbar'
import { MobileFriendlyStack } from 'components/stack'
import { prop } from 'ramda'
import { useRef, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import Resizer from 'react-image-file-resizer'

const resizeFile = (file): Promise<any> =>
    new Promise(resolve => {
        Resizer.imageFileResizer(file, 300, 300, 'PNG', 100, 0, resolve, 'blob')
    })

const Body = ({ settings }: { settings: OrgSettingsSchema }) => {
    const api = useApi()
    const showSnack = useShowSnack()
    const inputFile = useRef<any>(null)

    const [loading, setLoading] = useState(false)

    const { register, control, handleSubmit, formState, getValues, setValue, watch } =
        useForm<OrgSettingsSchema>({
            defaultValues: settings,
            resolver: zodResolver(OrgSettingsSchema),
        })

    const marketingAccountSameAsTrust = watch('marketingAccountSameAsTrust')

    const onSubmit: SubmitHandler<OrgSettingsSchema> = async data => {
        setLoading(true)
        try {
            await api.post('/orgsettings/update', data).then(prop('data'))
            showSnack('Organisation settings updated', 'success')
        } catch (e) {
            console.error(e)
            showSnack('Error saving organisation settings', 'danger')
        } finally {
            setLoading(false)
        }
    }

    if (Object.keys(formState.errors).length > 0) {
        console.log('formState.errors', formState.errors)
    }

    return (
        <>
            <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >
                <Stack spacing={1}>
                    <Stack
                        direction="row"
                        spacing={2}
                    >
                        <Controller
                            name="tradingName"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Trading Name"
                                    {...field}
                                    formControlProps={{
                                        sx: { flex: 1 },
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="licenseeName"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Licensee Name"
                                    {...field}
                                    formControlProps={{
                                        sx: { flex: 1 },
                                    }}
                                />
                            )}
                        />
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={2}
                    >
                        <Controller
                            name="abn"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="ABN"
                                    {...field}
                                    formControlProps={{
                                        sx: { flex: 1 },
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="acn"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="ACN"
                                    {...field}
                                    formControlProps={{
                                        sx: { flex: 1 },
                                    }}
                                />
                            )}
                        />
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={2}
                    >
                        <Controller
                            name="licenceNumber"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Licence Number"
                                    {...field}
                                    formControlProps={{
                                        sx: { flex: 1 },
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="licenceExpiry"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Licence Expiry"
                                    type="date"
                                    {...field}
                                    formControlProps={{
                                        sx: { flex: 1 },
                                    }}
                                />
                            )}
                        />
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={2}
                    >
                        <Controller
                            name="phone"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Phone Number"
                                    {...field}
                                    formControlProps={{
                                        sx: { flex: 1 },
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="fax"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Fax Number"
                                    {...field}
                                    formControlProps={{
                                        sx: { flex: 1 },
                                    }}
                                />
                            )}
                        />
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={2}
                    >
                        <Controller
                            name="email"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Email"
                                    {...field}
                                    formControlProps={{
                                        sx: { flex: 1 },
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="mobileNumber"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Mobile Number"
                                    {...field}
                                    formControlProps={{
                                        sx: { flex: 1 },
                                    }}
                                />
                            )}
                        />
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={2}
                    >
                        <Controller
                            name="website"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Website"
                                    {...field}
                                    formControlProps={{
                                        sx: { flex: 1 },
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="headOfficeCommission"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Head Office Commission"
                                    type="number"
                                    {...field}
                                    formControlProps={{
                                        sx: { flex: 1 },
                                    }}
                                    endDecorator="%"
                                />
                            )}
                        />
                    </Stack>
                </Stack>

                <Typography
                    level="h4"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Trust Account
                </Typography>
                <Stack spacing={1}>
                    <Controller
                        name="trustAccount.accountHolder"
                        control={control}
                        render={field => (
                            <SlotInput
                                label="Account Holder"
                                {...field}
                                formControlProps={{
                                    sx: { flex: 1 },
                                }}
                            />
                        )}
                    />
                    <Stack
                        direction="row"
                        spacing={2}
                    >
                        <Controller
                            name="trustAccount.name"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Account Name"
                                    {...field}
                                    formControlProps={{
                                        sx: { flex: 1 },
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="trustAccount.bank"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Bank"
                                    {...field}
                                    formControlProps={{
                                        sx: { flex: 1 },
                                    }}
                                />
                            )}
                        />
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={2}
                    >
                        <Controller
                            name="trustAccount.bsb"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="BSB"
                                    {...field}
                                    formControlProps={{
                                        sx: { flex: 1 },
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="trustAccount.accountNumber"
                            control={control}
                            render={field => (
                                <SlotInput
                                    label="Account Number"
                                    {...field}
                                    formControlProps={{
                                        sx: { flex: 1 },
                                    }}
                                />
                            )}
                        />
                    </Stack>
                </Stack>

                <Divider sx={{ my: 3 }} />

                <Typography
                    level="h4"
                    sx={{ mb: 2 }}
                >
                    Marketing Account
                </Typography>
                <Controller
                    name="marketingAccountSameAsTrust"
                    control={control}
                    render={field => (
                        <Checkbox
                            label="Same as Trust Account"
                            checked={field.field.value}
                            onChange={field.field.onChange}
                            sx={{ mb: 2 }}
                        />
                    )}
                />

                {!marketingAccountSameAsTrust && (
                    <Stack gap={1}>
                        <MobileFriendlyStack>
                            <Controller
                                name="marketingAccount.name"
                                control={control}
                                render={field => (
                                    <SlotInput
                                        label="Account Name"
                                        {...field}
                                        formControlProps={{
                                            sx: { flex: 1 },
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                name="marketingAccount.bank"
                                control={control}
                                render={field => (
                                    <SlotInput
                                        label="Bank"
                                        {...field}
                                        formControlProps={{
                                            sx: { flex: 1 },
                                        }}
                                    />
                                )}
                            />
                        </MobileFriendlyStack>
                        <MobileFriendlyStack>
                            <Controller
                                name="marketingAccount.bsb"
                                control={control}
                                render={field => (
                                    <SlotInput
                                        label="BSB"
                                        {...field}
                                        formControlProps={{
                                            sx: { flex: 1 },
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                name="marketingAccount.accountNumber"
                                control={control}
                                render={field => (
                                    <SlotInput
                                        label="Account Number"
                                        {...field}
                                        formControlProps={{
                                            sx: { flex: 1 },
                                        }}
                                    />
                                )}
                            />
                        </MobileFriendlyStack>
                    </Stack>
                )}

                <Divider sx={{ my: 3 }} />
                <Controller
                    name="marketingDisclosure"
                    control={control}
                    render={field => (
                        <SlotWrapper
                            label="Marketing Disclosure"
                            {...field}
                        >
                            <Textarea
                                {...field.field}
                                minRows={3}
                            />
                        </SlotWrapper>
                    )}
                />

                <Button
                    sx={{ mt: 4 }}
                    type="submit"
                    loading={loading}
                    startDecorator={<Save />}
                >
                    Save
                </Button>
            </form>
            <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={async e => {
                    const file = e.target.files?.[0]
                    if (file) {
                        const resized = await resizeFile(file)

                        //console.log('resized', resized)

                        const presignedUrl = await api.post('/orgsettings/logo').then(prop('data'))
                        await axios.put(presignedUrl, resized, {
                            headers: {
                                'Content-Type': resized.type,
                            },
                        })
                        window.location.reload()
                    }
                }}
            />
            <Box sx={{ mt: 4 }}>
                <Button onClick={() => inputFile.current!.click()}>Upload Logo</Button>
            </Box>
        </>
    )
}

const OrgDetails = () => {
    const orgDetails = useOrgDetailsApi()
    return orgDetails.maybeData.map(settings => <Body settings={settings} />).orSome(<Loading />)
}

export default OrgDetails
