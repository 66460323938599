import { Button, Link } from '@mui/joy'
import { useApi } from 'api'
import { useUserData } from 'auth'
import { useShowSnack } from 'components/snackbar'
import { prop } from 'ramda'
import { useState } from 'react'
import { useListingContext } from '../listingProvider'

export default () => {
    const api = useApi()
    const { user } = useUserData()
    const { listing, setListing } = useListingContext()
    const showSnack = useShowSnack()

    const [loading, setLoading] = useState(false)

    if (listing.marketingPaymentInvoice) {
        const { marketingPaymentInvoice } = listing
        return (
            <Link onClick={() => window.open(marketingPaymentInvoice?.url, '_blank')}>
                {marketingPaymentInvoice.invoiceNumber}
            </Link>
        )
    }

    if (!user.permissions.includes('listings.fullControl')) {
        return undefined
    }

    return (
        <Button
            loading={loading}
            disabled={!user.xeroConnected}
            variant="soft"
            onClick={async () => {
                setLoading(true)
                try {
                    const newListing = await api
                        .post(`/listings/${listing.listingId}/xero/marketinginvoice`)
                        .then(prop('data'))
                    setListing({ ...listing, marketingPaymentInvoice: newListing.marketingPaymentInvoice })
                    showSnack('Xero Invoice Created', 'success')
                } catch (e) {
                    console.error(e)
                    showSnack('Failed to create Xero Invoice', 'danger')
                }
                setLoading(false)
            }}
        >
            Create Xero Invoice
        </Button>
    )
}
